import React, { FC, ImgHTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import { useS3URL } from '../../../shared/use-s3-url';
import { useAppStore } from '../../../stores/context.store';

export const S3Image: FC<ImgHTMLAttributes<HTMLImageElement>> = observer(({ src, alt, ...rest }) => {
  const { auth } = useAppStore();
  const { s3Token } = auth;

  const { getS3URL } = useS3URL({ baseURL: '', s3Token });

  return <img src={getS3URL(src)} alt={alt} {...rest} />;
});
