import { IDashboard, IDashboardStage } from '@unione-pro/unione.assmnt.sdk.webapp';
import { ICourseStageDashboard } from '../models/stores/dashboard.store';
import { IModel } from '../models/stores/model.store';

interface IGetDashboardOpts {
  dashboard: IDashboard[];
  model?: IModel;
}

interface IStages {
  order: number;
  count: number;
  name: string;
}

interface ITesters {
  stages: IDashboardStage[];
  count: number;
}

export class DashboardMappers {

  public static getDashboard({ model, dashboard }: IGetDashboardOpts): ICourseStageDashboard[] {
    const testersCountLabel = model ? 'Всего тестируемых на оценочной сессии' : 'Всего тестируемых на оценочных сессиях';
    const modelStagesByOrder = model?.stages;

    const testers: ITesters = dashboard.reduce((acc, cur) => {
      acc.stages = [...acc.stages, ...cur.stages];
      acc.count += (cur.testers_count || 0);
      return acc;
    }, { stages: [], count: 0 });

    const stages = Object.values(testers.stages.reduce<Record<string, IStages>>((acc, cur) => {
      if (acc[cur.order]) {
        acc[cur.order] = {
          ...acc[cur.order],
          count: acc[cur.order].count + cur.testers_count,
        };
      }
      else {
        const name = modelStagesByOrder?.[cur.order] ? modelStagesByOrder[cur.order].name : `Этап ${cur.order}`;
        acc[cur.order] = {
          name,
          order: cur.order,
          count: cur.testers_count,
        };
      }
      return acc;
    }, {
      0: {
        name: testersCountLabel,
        order: 0,
        count: testers.count,
      },
    }));

    return stages;
  }

}
