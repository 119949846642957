export enum EnvKeys {
  filesStorage = 'REACT_APP_ASSMNT_S3_BUCKET_API',
  // builderAPI = 'REACT_APP_ASSMNT_BUILDERNEO_API',
  builderAPI = 'REACT_APP_ASSMNT_BUILDERNEXT_API',
  builderWEB = 'REACT_APP_ASSMNT_BUILDERNEO_WEB',
  aggregatorAPI = 'REACT_APP_ASSMNT_ENGINE_AGGREGATOR_API',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  localS3Token = 'REACT_APP_LOCAL_S3_TOKEN',
  yandexMetrikaId = 'REACT_APP_ASSMNT_YANDEX_METRIKA_ID',
  systemAPI = 'REACT_APP_ASSMNT_SYSTEM_API',
  loggerAPI = 'REACT_APP_LOGGER_API',
  loggerAPIKey = 'REACT_APP_LOGGER_API_KEY',
}

export interface IConfigStore {
  builderAPI: string;
  builderWEB: string;
  aggregatorAPI: string;
  filesStorage: string;
  systemAPI: string;
  loggerAPI: string | undefined;
  loggerAPIKey: string | undefined;
  localToken: string | undefined;
  localS3Token: string | undefined;
  yandexMetrikaId: string | undefined;
}
