import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { ChatIcon } from '../../lab/icons/chat';
import { ResultsIcon } from '../../lab/icons/results';
import { UserIcon } from '../../lab/icons/user';
import { UsersIcon } from '../../lab/icons/users';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      cursor: 'pointer',
      padding: '8px 12px 8px 20px',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      color: theme.palette.gloomyBlue.main,
      '&:hover': {
        backgroundColor: theme.palette.hover.main,
      },
    },
    activeLink: {
      backgroundColor: theme.palette.hover.main,
    },
    linkIcon: {
      minWidth: 20,
      maxWidth: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '@media (max-width: 1320px)': {
      root: {
        flexDirection: 'row',
        overflow: 'auto',
      },
      link: {
        whiteSpace: 'nowrap',
        padding: '18px 20px',
        font: {
          family: theme.fontFamily.monserrat,
          weight: 600,
        },
      },
    },
    '@media (max-width: 768px)': {
      link: {
        fontSize: 14,
        lineHeight: '16px',
      },
    },
  }),
  { name: 'sidebar' },
);

const navbarItems = [
  {
    icon: <UserIcon />,
    label: 'Ваш аккаунт-менеджер',
    href: BrowserRoute.accountManager,
  },
  {
    icon: <ResultsIcon />,
    label: 'Дашборды',
    href: BrowserRoute.dashboard,
  },
  {
    icon: <ChatIcon />,
    label: 'Обратная связь',
    href: BrowserRoute.feedback,
  },
  {
    icon: <UsersIcon />,
    label: 'Прокторинг',
    href: BrowserRoute.proctoring,
  },
];

export const Sidebar: FC = observer(() => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, 'scrollbar')}>
      {navbarItems.map(
        (el): JSX.Element => (
          <NavLink
            to={el.href}
            className={({ isActive }): string => (
              cn(classes.link, { [classes.activeLink]: isActive })
            )}
          >
            <div className={classes.linkIcon}>{el.icon}</div>
            <div className={classes.label}>{el.label}</div>
          </NavLink>
        ),
      )}
    </div>
  );
});
