import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { WarningIcon } from '../../../../lab/icons/warning';
import { useProctoringAttentionModalStyles } from './use-style';

type ProctoringAttentionModalProps = {
  onClose(): void;
  open: boolean;
};

export const ProctoringAttentionModal: FC<ProctoringAttentionModalProps> = observer(
  ({ open, onClose }) => {
    const classes = useProctoringAttentionModalStyles();

    return (
      <Modal open={open} onClose={onClose} size="sm" className={classes.modal}>
        <ModalHeader onClose={onClose} />

        <div className={classes.flex}>
          <div className={classes.icon}>
            <WarningIcon />
          </div>

          <div className={classes.content}>
            <h2 className={classes.title}>Внимание!</h2>
            <p className={classes.description}>
              Мы получили файл и уже начали его обрабатывать. Это может занять некоторое время.
              После успешной обработки вы получите письмо на электронную почту.
            </p>
          </div>
        </div>
      </Modal>
    );
  },
);
