import React from 'react';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { DownloadIcon } from '../../components/icons/download';
import { UploadIcon } from '../../components/icons/upload';
import { IntroCard } from '../../components/intro-card';
import { IProctoringIntroCardProps } from './proctoring.models';
import { useProctoringIntroCardStyle } from './use-styles';

export const ProctoringIntroCard: React.FC<IProctoringIntroCardProps> = ({
  onDownloadRegistry,
  onDownloadAgreements,
  uploadRegistry,
}) => {
  const classes = useProctoringIntroCardStyle();

  return (
    <IntroCard
      title="Прокторинг"
      summary={
        <div className={classes.summary}>
          <p className={classes.description}>
            На этом экране вы можете добавить пользователей, которые дали согласие на прохождение прокторинга.
          </p>
          <p>Для этого вам необходимо:</p>
          <ul className={classes.list}>
            <li>
              <span>01</span> Выгрузить шаблоны согласий
            </li>
            <li>
              <span>02</span> Распечатать и передать их тестируемым
            </li>
            <li>
              <span>03</span> Сформировать реестр согласившихся
            </li>
            <li>
              <span>04</span> Указать требуемый тип прокторинга для каждого тестируемого в сформированном файле
            </li>
            <li>
              <span>05</span> Загрузить реестр на платформу
            </li>
          </ul>
          <div className={classes.buttons}>
            <Button
              size="sm"
              variant="outlined"
              className={classes.download}
              onClick={onDownloadAgreements}
            >
              <span className={classes.bold}>
                Скачать шаблоны
              </span>
            </Button>
            <Button
              size="sm"
              variant="gradient"
              onClick={onDownloadRegistry}
            >
              <DownloadIcon />
              <span className={classes.bold}>
                Сформировать реестр
              </span>
            </Button>
            <label
              htmlFor="tester-registry"
              className={classes.upload}
            >
              <UploadIcon />
              <span className={classes.bold}>Загрузить реестр</span>
            </label>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden
              id="tester-registry"
              onChange={uploadRegistry}
            />
          </div>
        </div>
      }
      preview="/images/procto-preview.svg"
    />
  );
};
