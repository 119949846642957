import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
    },
    courseHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 16,
    },
    courseSelect: {
      width: '100%',
      maxWidth: 600,
      borderRadius: 10,
      color: theme.palette.secondaryText.main,
    },
    courseButton: {
      padding: '7px 23px',
      whiteSpace: 'nowrap',
    },
    courseInfo: {
      backgroundColor: '#F5FBFF',
      padding: '24px 40px',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 24,
      alignItems: 'center',
      borderRadius: 12,
    },
    courseInfoItem: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    courseInfoLabel: {
      color: theme.palette.secondaryText.main,
      lineHeight: '20px',
      fontSize: 16,
    },
    courseInfoCount: {
      fontFamily: theme.fontFamily.monserrat,
      color: theme.palette.primary.main,
      lineHeight: '24px',
      fontWeight: 600,
      fontSize: 20,
    },
    '@media (max-width: 1024px)': {
      courseSelect: {
        maxWidth: 'initial',
      },
      courseButton: {
        display: 'none',
      },
      courseInfo: {
        gridTemplateColumns: '1fr',
      },
      courseInfoItem: {
        gap: 12,
      },
    },
  }),
  { name: 'OneCourse' },
);
