export class BrowserRoute {

  public static main = '/';

  public static notFound = '/not-found';

  public static accountManager = '/manager';

  public static dashboard = '/dashboard';

  public static feedback = '/feedback';

  public static proctoring = '/proctoring';

}
