import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/context.store';
import { AccountProfileEmpty } from '../../components/account-profile-empty/account-profile-empty';
import { AccountProfile } from '../../components/account-profile/account-profile';
import { Typography } from '../../lab/typography';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      padding: '36px 48px',
    },
    profile: {
      marginTop: 24,
    },
    label: {
      fontFamily: theme.fontFamily.monserrat,
    },
    '@media (max-width: 1024px)': {
      root: {
        padding: 24,
      },
      profile: {
        marginTop: 20,
      },
    },
    '@media (max-width: 768px)': {
      root: {
        padding: 0,
        paddingTop: 24,
      },
      label: {
        padding: '0 24px',
      },
    },
  }),
  { name: 'accountManager' },
);

export const AccountManager: React.FC = observer((): JSX.Element => {
  const classes = useStyles();
  const {
    accountManager: { data, getAccountManager },
    user: { data: userData },
  } = useAppStore();

  useEffect(() => {
    if (userData?.organization?.id) {
      getAccountManager();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.organization?.id]);

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="h2" color="primary" fontWeight="bolder">
        Ваш аккаунт-менеджер
      </Typography>

      {data?.account_manager?.oneid ? (
        <AccountProfile className={classes.profile} />
      ) : (
        <AccountProfileEmpty className={classes.profile} />
      )}
    </div>
  );
});
