import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { IGetProctoTestersArgs, ISdkGetCoursesArgs } from '@unione-pro/unione.assmnt.sdk.webapp';
import { IMultiSelectOption } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { useDebounceEffect } from 'ahooks';
import { observer } from 'mobx-react';
import { withoutEmptyProperties } from '../../../shared/without-empty-properties';
import { useAppStore } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { ProctoringAttentionModal } from './components/proctoring-attention-modal';
import { ProctoringIntroCard } from './proctoring-intro-card';
import { ProctoringList } from './proctoring-list';
import { MobileProctoring } from './proctoring-mobile';

const INITIAL_PARAMS = { page: 1, limit: 10 };

export const Proctoring: React.FC = observer(() => {
  const { proctoTestersStore, user, registryStore, builder, courseDashboardStore } = useAppStore();
  const organizationId = user.data?.organization.id;
  const [searchValue, setSearchValue] = useState('');
  const [multiSelectSearchValue, setMultiSelectSearchValue] = useState('');
  const [params, setParams] = useState<IGetProctoTestersArgs>({ ...INITIAL_PARAMS, organizationId });
  const [multiSelectParams, setMultiSelectParams] = useState<ISdkGetCoursesArgs>(INITIAL_PARAMS);
  const [skipFirstDebounce, setSkipFirstDebounce] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (() => courseDashboardStore.reset()), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => courseDashboardStore.getUserCourses(withoutEmptyProperties(multiSelectParams)), [multiSelectParams]);

  useEffect(() => {
    proctoTestersStore.get(withoutEmptyProperties(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useDebounceEffect(
    () => {
      if (skipFirstDebounce) {
        return;
      }
      setMultiSelectParams((prev) => ({ ...prev, page: 1, title: multiSelectSearchValue }));
    },
    [multiSelectSearchValue],
    { wait: 500 },
  );

  useDebounceEffect(
    () => {
      setSkipFirstDebounce(false);
      if (skipFirstDebounce) {
        return;
      }
      setParams((prev) => ({ ...prev, page: 1, query: searchValue }));
    },
    [searchValue],
    { wait: 500 },
  );

  const isMobileClient = useMemo((): boolean => {
    const isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return isPhone || window.innerWidth < 1000;
  }, []);

  const { data, loading, error, fetching } = proctoTestersStore;

  const maxPaginationPage = useMemo(() => Math.ceil((data?.total || 0) / params.limit), [data?.total, params.limit]);

  const onDownloadRegistry = (): void => {
    registryStore.downloadRegistry(params.courseId);
  };

  const onDownloadAgreements = (): void => {
    registryStore.downloadAgreements(params.courseId);
  };

  const uploadRegistry = async(event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    await registryStore.uploadRegistry(formData);
    setParams({ ...params, ...INITIAL_PARAMS });
  };

  const goToPrevPage = (): void => {
    if (params.page > 1) {
      setParams((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const goToNextPage = (): void => {
    if (params.page < maxPaginationPage) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const changeLimit = (limit: number): void => {
    setParams({ organizationId, page: 1, limit });
  };

  const searchChange = (value: string): void => {
    setSearchValue(value.trim());
  };

  const multiSelectSearchChange = (value: string): void => {
    setMultiSelectSearchValue(value.trim());
  };

  const multiSelectInfinityScroll = (): void => {
    if (builder.courses.totalCount <= multiSelectParams.limit * multiSelectParams.page) {
      return;
    }
    setMultiSelectParams((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const multiSelectOnChange = (selected: IMultiSelectOption[]): void => {
    const courseId = selected.map((course) => course.value).join(',');
    setParams((prev) => ({ ...prev, page: 1, courseId }));
  };

  const intro = (): ReactElement => (
    <ProctoringIntroCard
      onDownloadRegistry={onDownloadRegistry}
      onDownloadAgreements={onDownloadAgreements}
      uploadRegistry={uploadRegistry}
    />
  );

  const closeProctoringAttentionModal = (): void => {
    registryStore.proctoringAttentionModalClose();
  };

  if (isMobileClient) {
    return <MobileProctoring />;
  }

  return (
    <>
      <Block
        data={{
          testers: data,
          courses: builder.courses.multiSelectOptions,
          coursesIsLoading: builder.courses.fetching,
        }}
        loading={loading}
        error={error}
        errorContent={intro}
      >
        {({ testers, courses, coursesIsLoading }): ReactElement => {
          if (!testers?.total && !fetching && typeof params.query === 'undefined' && typeof params.courseId === 'undefined') {
            return intro();
          }

          return (
            <ProctoringList
              onDownloadRegistry={onDownloadRegistry}
              onDownloadAgreements={onDownloadAgreements}
              uploadRegistry={uploadRegistry}
              data={testers}
              limit={params.limit}
              loading={fetching}
              currentPage={params.page}
              goToNextPage={goToNextPage}
              goToPrevPage={goToPrevPage}
              changeLimit={changeLimit}
              searchChange={searchChange}
              searchValue={searchValue}
              multiSelectOptions={courses}
              multiSelectOnChange={multiSelectOnChange}
              multiSelectIsLoading={coursesIsLoading}
              multiSelectSearchChange={multiSelectSearchChange}
              multiSelectInfinityScroll={multiSelectInfinityScroll}
              maxPaginationPage={maxPaginationPage}
            />
          );
        }}
      </Block>
      <ProctoringAttentionModal
        onClose={closeProctoringAttentionModal}
        open={registryStore.proctoringAttentionModalOpen}
      />
    </>
  );
});
