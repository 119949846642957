import React, { FC, SVGProps } from 'react';

export const DownloadIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 13L9.46967 13.5303C9.76256 13.8232 10.2374 13.8232 10.5303 13.5303L10 13ZM10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3L10.75 3ZM4.46967 8.53033L9.46967 13.5303L10.5303 12.4697L5.53033 7.46967L4.46967 8.53033ZM10.5303 13.5303L15.5303 8.53033L14.4697 7.46967L9.46967 12.4697L10.5303 13.5303ZM10.75 13L10.75 3L9.25 3L9.25 13L10.75 13Z"
      fill="currentColor"
    />
    <path
      d="M4 17.75C3.58579 17.75 3.25 17.4142 3.25 17C3.25 16.5858 3.58579 16.25 4 16.25V17.75ZM16 16.25C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75V16.25ZM4 16.25H16V17.75H4V16.25Z"
      fill="currentColor"
    />
  </svg>
);
