import { AxiosError } from 'axios';

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof AxiosError) {
    if (typeof error.response.data !== 'string') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return error.response.data.message ?? error.message;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return JSON.parse(error.response.data as string)?.message ?? error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

export const getErrorCode = (error: unknown): number | undefined => {
  if (error instanceof AxiosError) {
    return error.response.status;
  }

  return undefined;
};
