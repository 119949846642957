import { IRootStore } from '../models/stores/root.store';
import { AccountManagerStore } from './accountManager.store';
import { BuilderStore } from './builder/builder.store';
import { ConfigStore } from './config.store';
import { CourseDashboardStore } from './course-dashboard.store';
import { DashboardStore } from './dashboard.store';
import { LoggerStore } from './logger.store';
import { ModelStore } from './model.store';
import { ProctoTestersStore } from './procto-testers.store';
import { RegistryStore } from './registry.store';
import { TestingStore } from './testing/testing.store';
import { UserAuthStore } from './user.auth.store';
import { UserStore } from './user.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly user: UserStore;
  public readonly auth: UserAuthStore;
  public readonly testing: TestingStore;
  public readonly builder: BuilderStore;
  public readonly accountManager: AccountManagerStore;
  public readonly dashboardStore: DashboardStore;
  public readonly courseDashboardStore: CourseDashboardStore;
  public readonly registryStore: RegistryStore;
  public readonly proctoTestersStore: ProctoTestersStore;
  public readonly model: ModelStore;
  public readonly logger: LoggerStore;

  constructor() {
    this.auth = new UserAuthStore(this);
    this.user = new UserStore(this);
    this.config = new ConfigStore();
    this.testing = new TestingStore(this);
    this.builder = new BuilderStore(this);
    this.registryStore = new RegistryStore(this);
    this.accountManager = new AccountManagerStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.proctoTestersStore = new ProctoTestersStore(this);
    this.courseDashboardStore = new CourseDashboardStore(this);
    this.model = new ModelStore(this);
    this.logger = new LoggerStore(this);
  }

}
