export class BuilderBackendRoutes {

  public static userData = '/user/login';
  public static coursesByOrganizations = '/api/v3/moderate/organizations';
  public static coursesList = '/api/v3/moderate/courses';
  public static courseItem = '/api/v3/moderate/course/:courseId';
  public static updateStage = '/api/v3/moderate/course/update';
  public static getOrganization = '/organization/:organizationId';
  public static getAccountManagerInfo = '/api/v3/organization/:organizationId/account_manager/:userId';
  public static feedBack = '/api/v3/organization/account/feedback';

  // testing
  public static testerCourses = '/courses/tester';
  public static tester = '/testing/info';
  public static testingUserResults = '/aggregator/handler/get-users-results';
  public static testingPassedTests = '/aggregator/handler/get-passed-test';
  public static testingUserTest = '/testing/start';
  public static testingTestInfo = '/collector/handler/get-test-info';
  public static testingUpdateQuestionAnswer = '/collector/handler/upd-session';
  public static testingFinishTest = '/collector/handler/finish-session';

  // builder
  public static courses = '/course/:organization';
  public static course = '/course/:courseId';
  public static competency = (courseId: string): string => `/competency/${courseId}?stage=published`;
  public static competencies = '/api/v3/competency/filter?stage=published';

  // dashboard
  public static dashboard = '/users/dashboard/:courseId';
  public static courseResults = '/api/aggregator/handler/get-course-results';

  // registry
  public static testerRegistry = '/api/v1/testers/organization/testers-registry';
  public static testerAgreements = '/api/v1/testers/organization/tester-agreements';
  public static testersList = '/api/v2/testers/organization/testers-list';
  public static testersLatest = '/api/v1/testers/latest';

  // models
  public static model = '/api/v3/model/:id';

}
