import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ISdkGetCoursesArgs } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Tab, TabPanel, Tabs, useTabs } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { FolderIcon } from '../../lab/icons/folder';
import { GroupCourse, OneCourse } from './componetnts/CourseTab';
import { DashboardPlug } from './dashboard-plug';
import { useStyles } from './use-styles';

const INITIAL_PARAMS = { page: 1, limit: 10 };

export const Dashboard: React.FC = observer(() => {
  const { builder, courseDashboardStore, dashboardStore } = useContext(appStoreContext);
  const classes = useStyles();
  const tabs = useTabs('One');
  const [params, setParams] = useState<ISdkGetCoursesArgs>(INITIAL_PARAMS);

  const moreCourses = useCallback((): void => {
    if (builder.courses.fetching || builder.courses.totalCount <= params.limit * params.page) {
      return;
    }
    setParams(({ page, ...otherParams }) => ({ page: page + 1, ...otherParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const { run } = useDebounceFn((value: string) => {
    const newParams: ISdkGetCoursesArgs = { ...INITIAL_PARAMS };
    if (value) {
      newParams.title = value;
    }
    setParams(newParams);
  }, { wait: 500 });

  const changeFilterSetting = ({ is_it, industry_id }: Partial<ISdkGetCoursesArgs>): void => {
    const newParams: ISdkGetCoursesArgs = { ...INITIAL_PARAMS };
    if (typeof is_it !== 'undefined') {
      newParams.is_it = is_it;
    }
    if (Boolean(industry_id)) {
      newParams.industry_id = industry_id;
    }
    setParams(newParams);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (() => courseDashboardStore.reset()), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => courseDashboardStore.getUserCourses(params), [params]);

  useEffect(() => {
    dashboardStore.reset();
    setParams(INITIAL_PARAMS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.value]);

  if (!builder.courses.loading && !builder.courses.allCoursesCount) {
    return <DashboardPlug />;
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.label}>
        Дашборды
      </h2>

      <div>
        <div className={classes.courses}>
          <div className={classes.coursesIcon}>
            <FolderIcon />
          </div>
          <div className={classes.coursesInfo}>
            <h4 className={classes.coursesLabel}>
              Опубликованные оценочные сессии
            </h4>
            <p className={classes.coursesCount}>
              {builder.courses.allCoursesCount}
            </p>
          </div>
        </div>
        <div className={classes.course}>
          <Tabs className={classes.tabs} value={tabs.value} onChange={tabs.onChange}>
            <Tab className={classes.tab} value="One" label={'По отдельной оценочной сессий'} />
            <Tab className={classes.tab} value="Group" label={'По группе оценочных сессий'} />
          </Tabs>

          <TabPanel value="One" current={tabs.value}>
            <OneCourse moreCourses={moreCourses} searchCourses={run} show={tabs.value === 'One'} />
          </TabPanel>
          <TabPanel value="Group" current={tabs.value}>
            <GroupCourse moreCourses={moreCourses} changeSettings={changeFilterSetting} searchCourses={run} show={tabs.value === 'Group'} />
          </TabPanel>
        </div>
      </div>

    </div>
  );
});
