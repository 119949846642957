import { AxiosError } from 'axios';

export enum LOGGER_ACTION {
  CLICK_CREATE_REGISTRY = 'click_create_registry',
  CLICK_DOWNLOAD_AGREEMENTS = 'click_download_agreements',

  ERROR_CREATE_REGISTRY = 'error_create_registry',
  ERROR_DOWNLOAD_AGREEMENTS = 'error_download_agreements',

  ACTIVATED_ERROR_BOUNDARY = 'activated_error_boundary',
}

export interface ISendLogArgs {
  action: LOGGER_ACTION;
  request: object;
  response?: object;
  error?: AxiosError;
}

export interface ILoggerStore {
  sendLog(args: ISendLogArgs): Promise<void>;
}
