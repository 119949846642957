import { DisplayResults, IBuilderCourse, IGrade, TestType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { IChartData, IChartCourseResult, IResult } from '../models/stores/builder.store';
import { IModel } from '../models/stores/model.store';


interface IGetCourseResultOpts {
  results: IResult[];
  course: IBuilderCourse<string>;
  model?: IModel;
}

interface ITargetLevel {
  targetValue: number;
  targetValueGrade: IGrade;
}

interface ILevelValue {
  [key: string]: ITargetLevel;
}

const MAX_PROCENT = 100;

export class CourseResultMappers {

  public static getCourseResult(opts: IGetCourseResultOpts): IChartCourseResult {
    const { competencies, stages, testing } = opts.course;

    const displayResults = opts.model?.display_results;
    const onlySphere = displayResults === DisplayResults.sphere;
    const onlyCompetency = displayResults === DisplayResults.competency;
    const testTypeIsSphere = opts.model?.test_options.test_type === TestType.sphere;
    const levelsFrom: ILevelValue = opts.model?.levels.reduce((result, level) => ({ ...result, [level.type]: level.from / MAX_PROCENT }), {}) || {};
    const targetValue: ILevelValue = testing.reduce(
      (result, course) => ({
        ...result,
        [course._id]: { targetValue: levelsFrom[course.level], targetValueGrade: course.level },
      }),
      {},
    );

    const getTitle = (sphereTitle: string, competencyTitle: string): string => {
      if (onlySphere) {
        return sphereTitle;
      }
      if (onlyCompetency) {
        return competencyTitle;
      }
      return `(${sphereTitle}) - ${competencyTitle}`;
    };

    const charts: IChartData[] = competencies!.map((competency, index) => {
      const { _id: competencyId, title: competencyTitle, sphere } = competency;
      const { _id: sphereId, title: sphereTitle } = sphere;
      const targetId = testTypeIsSphere ? sphereId : competencyId;
      const chart: IChartData = {
        key: index + 1,
        title: getTitle(sphereTitle, competencyTitle),
        id: onlySphere ? sphereId : competencyId,
        grade: {},
      };

      for (const stage of stages) {
        const result = {
          sphere: opts.results.find((res) => res?.sphere_id === sphereId && res.course_stage === stage.order),
          competency: opts.results.find(
            (res) => res.competence_id === competencyId && res.course_stage === stage.order,
          ),
        };

        if (Boolean(result.competency?.total)) {
          chart[`competency_${stage.order}`] = result.competency?.total;
          chart.grade[`competency_${stage.order}`] = result.competency?.grade;
        }

        if (Boolean(result.sphere?.total)) {
          chart[`sphere_${stage.order}`] = result.sphere?.total;
          chart.grade[`sphere_${stage.order}`] = result.sphere?.grade;
        }
      }

      chart.targetValue = targetValue[targetId].targetValue;
      chart.grade.targetValue = targetValue[targetId].targetValueGrade;

      return chart;
    });

    const chartStages = stages.map((stage) => {
      const modelStage = opts.model?.stages[stage.order];

      return {
        name: modelStage?.name || `Этап #${stage.order}`,
        color: modelStage?.color || '#a4acbc',
        dataKey: stage.order,
      };
    });

    return {
      charts,
      stages: chartStages,
    };
  }

}
