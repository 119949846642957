import React, { FC, SVGProps } from 'react';

export const FolderIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.75 12C6.75 11.036 6.75159 10.3884 6.81654 9.90539C6.87858 9.44393 6.9858 9.24643 7.11612 9.11612C7.24643 8.9858 7.44393 8.87858 7.90539 8.81654C8.38843 8.75159 9.03599 8.75 10 8.75H14.8672C15.7334 8.75 15.9606 8.76292 16.156 8.8427C16.3515 8.92248 16.5228 9.07224 17.1415 9.67833L20.0588 12.5361C20.0875 12.5642 20.1158 12.592 20.1438 12.6194C20.6354 13.1019 21.0244 13.4837 21.5271 13.6889C22.0298 13.8941 22.5749 13.8936 23.2637 13.8929C23.3029 13.8929 23.3426 13.8929 23.3827 13.8929H30C30.964 13.8929 31.6116 13.8944 32.0946 13.9594C32.5561 14.0214 32.7536 14.1287 32.8839 14.259C33.0142 14.3893 33.1214 14.5868 33.1835 15.0482C33.2484 15.5313 33.25 16.1788 33.25 17.1429V28C33.25 28.964 33.2484 29.6116 33.1835 30.0946C33.1214 30.5561 33.0142 30.7536 32.8839 30.8839C32.7536 31.0142 32.5561 31.1214 32.0946 31.1835C31.6116 31.2484 30.964 31.25 30 31.25H10C9.03599 31.25 8.38843 31.2484 7.90539 31.1835C7.44393 31.1214 7.24643 31.0142 7.11612 30.8839C6.9858 30.7536 6.87858 30.5561 6.81654 30.0946C6.75159 29.6116 6.75 28.964 6.75 28V12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
