import React, { FC } from 'react';
import { IGrade } from '@unione-pro/unione.assmnt.sdk.webapp';
import { ICompetencyLevelResult, LEVEL_RESULT_INFO, LEVEL_RESULT_INFO_BY_GRADE, MIN_SCORE_LEVEL } from '../../../constants';
import { IChartCustomTooltip } from './result.models';
import { useCustomTooltipStyles } from './use-styles';

export const CustomTooltip: FC<IChartCustomTooltip> = (props) => {
  const classes = useCustomTooltipStyles();

  const getLevelResultInfo = (score?: number, grade?: IGrade): ICompetencyLevelResult | undefined => {
    if (grade) {
      return LEVEL_RESULT_INFO_BY_GRADE[grade];
    }

    if (typeof score !== 'number') {
      return undefined;
    }

    if (score >= MIN_SCORE_LEVEL.expert) {
      return LEVEL_RESULT_INFO.expert;
    }

    if (score >= MIN_SCORE_LEVEL.advanced) {
      return LEVEL_RESULT_INFO.advanced;
    }

    if (score >= MIN_SCORE_LEVEL.normal) {
      return LEVEL_RESULT_INFO.normal;
    }

    return LEVEL_RESULT_INFO.min;
  };

  if (!props.active || !props.payload) {
    return null;
  }

  return (
    <div className={classes.tooltip}>
      <h2 className={classes.title}>{props.title}</h2>
      {props.payload.map((el, index) => (
        <p key={index} style={{ color: el.color as string }}>
          <span>{`${el.name}: ${getLevelResultInfo(el.value as number, el.grade)?.levelLabel} `}</span>
          <span>({Number(el.value).toFixed(2)})</span>
        </p>
      ))}
    </div>
  );
};
