import { CourseStage, ISdkGetCoursesArgs } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { ICourseDashboardStore } from '../models/stores/course-dashboard.store';
import { IRootStore } from '../models/stores/root.store';

export class CourseDashboardStore implements ICourseDashboardStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public get userOrganizationId(): string | undefined {
    return this.rootStore.user.data?.organization?.id;
  }

  public get courseCount(): number | undefined {
    return this.rootStore.builder.courses.totalCount;
  }

  public getUserCourses(params: ISdkGetCoursesArgs): void {
    if (!this.userOrganizationId) {
      return;
    }

    if (params.page === 1) {
      this.rootStore.builder.courses.load({
        organization: this.userOrganizationId,
        stages: CourseStage.published,
        ...params,
      });
    }
    else {
      this.rootStore.builder.courses.fetch({
        organization: this.userOrganizationId,
        stages: CourseStage.published,
        ...params,
      });
    }
  }

  public async get(courseId: string, modelId?: string): Promise<void> {
    runInAction(() => {
      this.loading = true;
    });

    if (modelId) {
      await this.rootStore.model.get(modelId);
      await this.rootStore.builder.course.load({ courseId });
      await this.rootStore.dashboardStore.get(courseId);
      await this.rootStore.testing.courseTestingResults.loadResults(courseId);
    }
    else {
      const courseIds = courseId.split(',');
      this.rootStore.model.reset();
      this.rootStore.builder.course.reset();
      await this.rootStore.dashboardStore.get(courseId);
      await this.rootStore.testing.courseTestingResults.loadCoursesResults(courseIds);
    }

    runInAction(() => {
      this.loading = false;
    });
  }

  public reset(): void {
    this.rootStore.model.reset();
    this.rootStore.dashboardStore.reset();
    this.rootStore.builder.course.reset();
    this.rootStore.builder.courses.reset();
    this.rootStore.testing.courseTestingResults.reset();
  }

}
