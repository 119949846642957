import { IRootStore } from '../../models/stores/root.store';
import { ITestingStore, ICourseTestingResultsStore } from '../../models/stores/testing.store';
import { CourseTestingResultsStore } from './testing-course-results.store';

export class TestingStore implements ITestingStore {

  public readonly rootStore: IRootStore;
  public courseTestingResults: ICourseTestingResultsStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    this.courseTestingResults = new CourseTestingResultsStore(rootStore);
  }

}
