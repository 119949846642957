import React, { ReactElement, useContext, FC, createRef } from 'react';
import { Button, Select, toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { ICourseOption } from '../../../../../../models/stores/builder.store';
import { createFileName, useScreenshot } from '../../../../../../shared/takeScreenshot';
import { appStoreContext } from '../../../../../../stores/context.store';
import { Block } from '../../../../../components/block';
import { OneCourseChart } from '../../../../../components/chart';
import { ICourseTabProps } from '../CourseTab.model';
import { useStyles } from './use-styles';

export const OneCourse: FC<ICourseTabProps> = observer(({ moreCourses, searchCourses, show }) => {
  const { courseDashboardStore, dashboardStore, builder, testing, model } = useContext(appStoreContext);
  const classes = useStyles();

  const result = testing.courseTestingResults.data;

  const onCourseSelect = (option: ICourseOption): void => {
    if (option.value) {
      courseDashboardStore.get(String(option.value), option.modelId);
    }
    else {
      dashboardStore.reset();
    }
  };

  const imageContainerRef = createRef<HTMLDivElement>();

  const { takeScreenShot } = useScreenshot({
    type: 'image/png',
    quality: 1.0,
  });

  const downloadImage = async(): Promise<void> => {
    const screenshot = await takeScreenShot(imageContainerRef.current);

    if (typeof screenshot !== 'string') {
      toast({
        text: 'Произошла ошибка, повторите снова.',
        loadingBar: true,
        type: 'error',
      });
    }

    const a = document.createElement('a');
    a.href = screenshot as string;
    a.download = createFileName({ extension: 'png', names: ['dashboard results'] });
    a.click();
  };

  return (
    <div className={classes.root}>
      {show && (
        <div className={classes.courseHeader}>
          <Select
            placeholder="Выберите оценочную сессию"
            options={builder.courses.options}
            onChange={onCourseSelect}
            className={classes.courseSelect}
            withShadow={true}
            infinityScroll={moreCourses}
            isLoading={builder.courses.fetching}
            handleSearch={searchCourses}
          />
          {dashboardStore.data && !courseDashboardStore.loading && (
            <Button
              variant={'outlined'}
              onClick={(): void => {
                downloadImage();
              }}
              className={classes.courseButton}
              size="none"
            >
              Скачать в PNG
            </Button>
          )}
        </div>
      )}
      <Block
        data={dashboardStore.data}
        loading={courseDashboardStore.loading}
        children={(dashboard): ReactElement => (
          <div style={{ display: dashboard ? 'block' : 'none' }} ref={imageContainerRef}>
            {dashboard && (
              <div className={classes.courseInfo}>
                {dashboard.map((stage) => (
                  <div key={stage.order} className={classes.courseInfoItem}>
                    <p className={classes.courseInfoLabel}>
                      {stage.name}
                    </p>
                    <p className={classes.courseInfoCount}>
                      {stage.count}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {result && model.data && <OneCourseChart charts={result.charts} stages={result.stages} displayResults={model.data.display_results} />}
          </div>
        )}
      />
    </div>
  );
});
