import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      margin: 24,
      padding: 20,
      backgroundColor: theme.background.paper,
      minHeight: 'calc(100vh - 157px)',
      borderRadius: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 40,
      fontSize: 16,
      lineHeight: '20px',
      textAlign: 'center',
    },
    imgWrapper: {
      width: 230,
    },
    img: {
      maxWidth: '100%',
    },
    description: {
      '& > span': {
        color: theme.palette.primary.main,
      },
    },
    '@media (max-width: 1320px)': {
      root: {
        minHeight: 'calc(100vh - 213px)',
      },
    },
    '@media (max-width: 768px)': {
      root: {
        minHeight: 'calc(100vh - 233px)',
      },
    },
  }),
  { name: 'proctoring' },
);

export const MobileProctoring: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div className={classes.imgWrapper}>
          <img className={classes.img} src={`${process.env.PUBLIC_URL}/images/procto-mobile.svg`} alt="" />
        </div>
        <p className={classes.description}>
          Раздел <span>&lt;Прокторинг&gt;</span> доступен только с веб-версии платформы.
          <br />Зайдите в сервис с компьютера.
        </p>
      </div>
    </div>
  );
};
