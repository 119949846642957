import React, { FC, SVGProps } from 'react';

export const UsersIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 1.90267C7.35829 1.49647 7.83179 1.20901 8.35747 1.07853C8.88316 0.948053 9.43612 0.980749 9.94277 1.17227C10.4494 1.36379 10.8857 1.70505 11.1937 2.15064C11.5016 2.59623 11.6665 3.12503 11.6665 3.66667C11.6665 4.20831 11.5016 4.7371 11.1937 5.18269C10.8857 5.62829 10.4494 5.96955 9.94277 6.16107C9.43612 6.35259 8.88316 6.38528 8.35747 6.25481C7.83179 6.12433 7.35829 5.83687 7 5.43067M9 13H1V12.3333C1 11.2725 1.42143 10.2551 2.17157 9.50491C2.92172 8.75476 3.93913 8.33333 5 8.33333C6.06087 8.33333 7.07828 8.75476 7.82843 9.50491C8.57857 10.2551 9 11.2725 9 12.3333V13ZM9 13H13V12.3333C13.0001 11.6311 12.8153 10.9413 12.4643 10.3331C12.1133 9.72498 11.6083 9.21995 11.0002 8.86881C10.3922 8.51767 9.70234 8.33279 9.00014 8.33277C8.29795 8.33274 7.60812 8.51757 7 8.86867M7.66667 3.66667C7.66667 4.37391 7.38572 5.05219 6.88562 5.55229C6.38552 6.05238 5.70724 6.33333 5 6.33333C4.29276 6.33333 3.61448 6.05238 3.11438 5.55229C2.61428 5.05219 2.33333 4.37391 2.33333 3.66667C2.33333 2.95942 2.61428 2.28115 3.11438 1.78105C3.61448 1.28095 4.29276 1 5 1C5.70724 1 6.38552 1.28095 6.88562 1.78105C7.38572 2.28115 7.66667 2.95942 7.66667 3.66667Z"
      stroke="#7D6FE1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
