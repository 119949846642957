import React from 'react';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { Typography } from '../../lab/typography';

type IntroCardProps = React.PropsWithChildren<{ title: string; summary: React.ReactNode; preview?: string }>;

export const IntroCard: React.FC<IntroCardProps> = (props) => {
  const { title, preview, summary } = props;

  return (
    <div className="flex flex-col space-y-4 p-4 w-full">
      <Paper className="flex justify-between min-h-[468px] m-8 overflow-hidden">
        <div className="flex flex-col gap-6 items-start py-4 px-10 md:pr-0 md:pl-[5.5rem] max-w-[621px] py-20 w-full">
          <Typography variant="h1" component="h1" fontWeight="bolder" color="primary">
            {title}
          </Typography>
          <Typography color="textSecondary" variant="h5">
            {summary}
          </Typography>
        </div>

        {preview && (
          <div className="flex justify-end w-6/12 overflow-hidden">
            <img src={preview} alt={title} className="h-full object-cover object-left" />
          </div>
        )}
      </Paper>
    </div>
  );
};
