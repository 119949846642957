import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { S3Image } from '../../components/s3-image';
import { UserIcon } from '../icons/user';
import { AvatarProps } from './avatar.models';

const PREFIX = 'Avatar';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: 160,
      height: 160,
      borderRadius: '200px',
      background: 'rgb(245 246 248 / 1)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      padding: 12,
    },
    icon: {
      width: 60,
      height: 60,
      stroke: 'gray',
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
    hasImg: {
      padding: 0,
    },
  }),
  { name: PREFIX },
);

export const Avatar: React.FC<AvatarProps> = (exampleProps) => {
  const { className, src, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.hasImg]: !!src }, className);

  return (
    <div className={rootCN} {...other}>
      {src && <S3Image className={classes.image} src={src || ''} alt="" />}
      {!src && <UserIcon className={classes.icon} />}
    </div>
  );
};
