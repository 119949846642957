import React from 'react';
import { createUseStyles } from 'react-jss';
import { FeedbackForm } from '../../components/feedback-form/feedback-form';
import { Typography } from '../../lab/typography';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      padding: '36px 48px',
    },
    label: {
      fontFamily: theme.fontFamily.monserrat,
    },
    '@media (max-width: 1024px)': {
      root: {
        padding: '39px 24px',
      },
    },
    '@media (max-width: 768px)': {
      root: {
        padding: 0,
        paddingTop: 39,
      },
      label: {
        padding: '0 24px',
      },
    },
  }),
  { name: 'feedback' },
);

export const Feedback: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.label} component="h2" color="primary" variant="h2" fontWeight="bolder">
        Обратная связь
      </Typography>

      <FeedbackForm />
    </div>
  );
};
