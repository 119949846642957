import { ICourseModel } from '@unione-pro/unione.assmnt.sdk.webapp';
import { IModel } from '../models/stores/model.store';

export class ModelMappers {

  public static getModel(model: ICourseModel): IModel {
    const stages = model.stages.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.order]: cur,
      }),
      {},
    );
    return { ...model, stages };
  }

}
