import { IBuilderCourse, ISdkGetCoursesArgs, sdkGetCourses } from '@unione-pro/unione.assmnt.sdk.webapp';
import { IMultiSelectOption } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { IBuilderCoursesStore, ICourseOption } from '../../models/stores/builder.store';
import { IRootStore } from '../../models/stores/root.store';
import { getErrorMessage } from '../../shared/error-message';

export class BuilderCoursesStore implements IBuilderCoursesStore {

  public readonly rootStore: IRootStore;

  public data: IBuilderCourse<string>[] = [];
  public allCoursesCount: number = 0;
  public totalCount: number | undefined = undefined;
  public fetching: boolean = true;
  public loading: boolean = true;
  public error: string | null = null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public get options(): ICourseOption[] {
    if (!this.data) {
      return [];
    }

    const options: ICourseOption[] = this.data.map((course) => ({
      label: course.title,
      value: course._id,
      modelId: course.model_id,
    }));

    return options;
  }

  public get multiSelectOptions() : IMultiSelectOption[] {
    if (!this.data) {
      return [];
    }

    const multiSelectOptions: IMultiSelectOption[] = this.data.map((course) => ({
      label: course.title,
      value: course._id,
    }));

    return multiSelectOptions;
  }

  public async load(params: ISdkGetCoursesArgs): Promise<void> {
    const requiredKey = ['organization', 'stages', 'limit', 'page'];
    const hasOnlyPageAndLimit = Object.keys(params).every((key) => requiredKey.includes(key));

    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.fetching = true;
        this.error = null;
      });

      const response = await sdkGetCourses({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.auth.token,
        data: params,
      });

      runInAction(() => {
        this.loading = false;
        this.fetching = false;
        this.data = response.items;
        this.totalCount = response.totalCount;
        if (hasOnlyPageAndLimit) {
          this.allCoursesCount = response.totalCount;
        }
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.loading = false;
        this.fetching = false;
        this.error = getErrorMessage(error);
      });
    }
  }

  public async fetch(params: ISdkGetCoursesArgs): Promise<void> {
    try {
      runInAction(() => {
        this.fetching = true;
        this.error = null;
      });

      const response = await sdkGetCourses({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.auth.token,
        data: params,
      });

      runInAction(() => {
        this.fetching = false;
        this.data = this.data.concat(response.items);
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.fetching = false;
        this.error = getErrorMessage(error);
      });
    }
  }

  public reset(): void {
    this.data = null;
    this.loading = true;
    this.fetching = true;
    this.totalCount = undefined;
    this.error = null;
  }

}
