import React, { FC, Fragment, ReactNode, useMemo, useState } from 'react';
import { DisplayResults } from '@unione-pro/unione.assmnt.sdk.webapp';
import cn from 'classnames';
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { CustomTooltip } from './custom-tooltip';
import { IHandleHiddenOpts, IChartProps } from './result.models';
import { useChartStyles } from './use-styles';

const MAX_PHONE_WIDTH = 1024;
const RADAR_CHART_OUTER_RADIUS = 120;

export const OneCourseChart: FC<IChartProps> = (props) => {
  const [hidden, setHidden] = useState<{ [key: string]: boolean }>({});
  const classes = useChartStyles();
  const isMobile = window.innerWidth <= MAX_PHONE_WIDTH;

  const getTitle = (key: number): string => {
    const competency = props.charts.find((chart) => chart.key === key);

    if (!competency) {
      return '—';
    }

    return `${key}. ${competency.title}`;
  };

  const handleHidden = (opts: IHandleHiddenOpts): void => {
    const { dataKey, inactive } = opts;

    if (dataKey) {
      setHidden((hide) => ({ ...hide, [dataKey as number]: !inactive }));
    }
  };

  const targetValueDot = useMemo(() => {
    if (props.charts.length > 2) {
      return [];
    }
    const targetYCordsArr = props.charts.map((chart, index) => {
      const value = Number(chart.targetValue) * RADAR_CHART_OUTER_RADIUS;
      return Boolean(index) ? value : -value;
    });
    return targetYCordsArr;
  }, [props.charts]);

  const getStageName = (name: string, type: string): string => {
    if (props.displayResults === DisplayResults.competencyAndSphere) {
      return `${name}(${type})`;
    }
    return name;
  };

  return (
    <div className={cn(classes.wrapper, classes.oneCourseWrapper)}>
      <ResponsiveContainer
        height={400}
        children={
          <RadarChart outerRadius={RADAR_CHART_OUTER_RADIUS} data={props.charts} className={classes.chart}>
            <PolarGrid radialLines={true} gridType="circle" />
            <PolarAngleAxis dataKey="key" tickSize={20} />
            <PolarRadiusAxis angle={90} domain={[0, 1]} />
            <Radar dot name="Целевой уровень" dataKey={'targetValue'} stroke="gray" fillOpacity={0} />
            {props?.displayResults !== DisplayResults.sphere && props.stages.map((stage) => (
              <Radar
                key={`competency_${stage.dataKey}`}
                hide={hidden[`competency_${stage.dataKey}`]}
                dot
                name={getStageName(stage.name, 'Компетенция')}
                dataKey={`competency_${stage.dataKey}`}
                fill={stage.color}
                fillOpacity={0.6}
              />
            ))}
            {props?.displayResults !== DisplayResults.competency && props.stages.map((stage) => (
              <Radar
                key={`sphere_${stage.dataKey}`}
                hide={hidden[`sphere_${stage.dataKey}`]}
                dot
                name={getStageName(stage.name, 'Сфера')}
                dataKey={`sphere_${stage.dataKey}`}
                fill={stage.color}
                fillOpacity={0.6}
              />
            ))}
            <Tooltip
              content={(tooltip): ReactNode => (
                <CustomTooltip
                  active={tooltip.active}
                  title={getTitle(tooltip.label)}
                  payload={tooltip.payload?.map((tool) => ({
                    color: tool.color,
                    name: tool.name,
                    value: tool.value,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    grade: tool.payload.grade[tool.dataKey as string],
                  }))}
                />
              )}
            />
            {targetValueDot.map((yCord, index) => (
              <g key={index} className={classes.targetValueLine}>
                <line x1={-20} x2={20} y1={yCord} y2={yCord} stroke="gray" />
              </g>
            ))}
            <Legend
              iconType="plainline"
              align={isMobile ? 'left' : 'right'}
              verticalAlign={isMobile ? 'bottom' : 'top'}
              layout="vertical"
              onClick={handleHidden}
            />
          </RadarChart>
        }
      />
      <div className={classes.competencies}>
        {props.charts.map((chart) => (
          <Fragment key={chart.id}>
            <div className={classes.competencyKey}>{chart.key}</div>
            <div>{chart.title}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
