import { sdkSendLog } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable } from 'mobx';
import { ILoggerStore, ISendLogArgs } from '../models/stores/logger.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorCode, getErrorMessage } from '../shared/error-message';

export class LoggerStore implements ILoggerStore {

  public readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public sendLog = async({ action, request, response, error }: ISendLogArgs): Promise<void> => {
    if (!this.rootStore.user.data) {
      return;
    }

    const errorData = error ? {
      message: getErrorMessage(error),
      statusCode: getErrorCode(error),
    } : {};
    const res = {
      ...response,
      ...errorData,
    };

    await sdkSendLog({
      baseURL: this.rootStore.config.loggerAPI,
      headers: {
        'x-api-key': this.rootStore.config.loggerAPIKey,
      },
      token: this.rootStore.auth.token,
      data: {
        uid: this.rootStore.user.data.id,
        act: action,
        ts: new Date(),
        req: request,
        res,
      },
    });
  };

}
