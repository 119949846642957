import {
  IBuilderCourse,
  ISdkGetCourseArgs,
  sdkGetCourse,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { IBuilderCourseStore } from '../../models/stores/builder.store';
import { IRootStore } from '../../models/stores/root.store';
import { getErrorMessage } from '../../shared/error-message';

export class BuilderCourseStore implements IBuilderCourseStore {

  public readonly rootStore: IRootStore;

  public data: IBuilderCourse<string> | null = null;
  public loading: boolean = true;
  public error: string | undefined = undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async load(params: ISdkGetCourseArgs): Promise<void> {
    try {
      runInAction(() => {
        this.loading = true;
        this.data = null;
        this.error = null;
      });

      const response = await sdkGetCourse({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.auth.token,
        data: params,
      });

      runInAction(() => {
        this.data = response;
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public reset(): void {
    this.data = null;
    this.loading = false;
    this.error = null;
  }

}
