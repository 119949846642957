import { ITestingCourseResult, sdkGetCourseResults, sdkGetCoursesResults } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { CourseResultMappers } from '../../mappers/course-result.mappers';
import { IChartCourseResult, IChartData, IResult } from '../../models/stores/builder.store';
import { IRootStore } from '../../models/stores/root.store';
import { ICourseTestingResultsStore } from '../../models/stores/testing.store';
import { getErrorMessage } from '../../shared/error-message';

const COURSE_STAGE_COLORS = [
  '#808080',
  '#E26C3C',
  '#F39A9A',
  '#F7B055',
  '#7D6FE1',
  '#73AAFF',
  '#99C6EB',
  '#0071CE',
  '#07B4CF',
  '#7BE7C7',
  '#CAD753',
];

interface ICoursesChartsResult extends ITestingCourseResult {
  order: number;
}

export class CourseTestingResultsStore implements ICourseTestingResultsStore {

  public readonly rootStore: IRootStore;

  public data: IChartCourseResult | null;
  public loading: boolean;
  public error?: string | undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset(): void {
    this.data = null;
    this.loading = false;
    this.error = undefined;
  }

  public loadCoursesResults = async(ids: string[]): Promise<void> => {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetCoursesResults({
        baseURL: this.rootStore.config.aggregatorAPI,
        token: this.rootStore.auth.token,
        data: {
          ids,
        },
      });

      const results: ICoursesChartsResult[] = [];
      const uniqueCompetenceIds = new Set();

      const stages = response.values.flat().map((res) => {
        for (const result of res.results) {
          uniqueCompetenceIds.add(result.competence_id);
          results.push({ ...result, order: res.course_stage });
        }

        const name = res.course_stage === 0 ? 'Среднее за все этапы' : `Этап ${res.course_stage}`;

        return ({
          dataKey: res.course_stage,
          color: COURSE_STAGE_COLORS[res.course_stage],
          name,
        });
      });

      const uniqueCompetenceIdsArray = Array.from(uniqueCompetenceIds);

      const charts: IChartData[] = uniqueCompetenceIdsArray.map((compId, index) => {
        const competency_result = results.filter((res) => res.competence_id === compId);
        return competency_result.reduce<IChartData>((acc, cur) => ({
          ...acc,
          id: cur.competence_id,
          title: cur.comp_title || cur.competence_id,
          grade: { ...acc.grade, [cur.order]: cur.grade },
          [cur.order]: cur.total.toFixed(2),
        }), { id: competency_result[0].competence_id, key: index, title: competency_result[0].comp_title || competency_result[0].competence_id, grade: {} });
      });
      runInAction(() => {
        this.loading = false;
        this.data = { charts, stages };
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  };

  public async loadResults(courseId: string): Promise<void> {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
      });

      const course = this.rootStore.builder.course.data;

      const response = await sdkGetCourseResults({
        baseURL: this.rootStore.config.aggregatorAPI,
        token: this.rootStore.auth.token,
        data: {
          'course-id': courseId,
        },
      });

      const results: IResult[] = response.values
        .flat()
        .map((result) => ({
          competence_id: result['competence-id'],
          sphere_id: result['sphere-id'],
          course_stage: result['course-stage'],
          total: result.total,
          grade: result.grade,
        }));

      runInAction(() => {
        this.loading = false;
        this.data = CourseResultMappers.getCourseResult({
          results,
          course,
          model: this.rootStore.model.data,
        });
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  }

}
