import React, { FC } from 'react';
import { Paper, PaperProps } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { useAppStore } from '../../../stores/context.store';
import { Avatar } from '../../lab/avatar';
import { Typography } from '../../lab/typography';
import { useAccountProfileStyles } from './account-profile.styles';

export const AccountProfile: FC<PaperProps> = (props) => {
  const classes = useAccountProfileStyles();

  const { className, ...other } = props;
  const rootCN = cn(classes.root, className);

  const {
    config,
    accountManager,
  } = useAppStore();

  const {
    email,
    name,
    patronymic,
    sur_name,
    avatar,
    phone,
  } = accountManager.data.account_manager.oneid;

  return (
    <Paper className={rootCN} {...other}>
      <Avatar className={classes.avatar} src={avatar ? `${config.filesStorage}/${avatar}` : ''} />

      <div className={classes.box}>
        <Typography className={classes.fullName} color="primary" variant="h3" fontWeight="bold">
          {sur_name} {name} {patronymic}
        </Typography>

        <div className={classes.infoBox}>
          <div className={classes.infoField}>
            <Typography className={classes.infoLabel} variant="body1" color="textGray">
              Телефон
            </Typography>
            <Typography color="primary">{phone}</Typography>
          </div>
          <div className={classes.infoField}>
            <Typography className={classes.infoLabel} variant="body1" color="textGray">
              E-mail
            </Typography>
            <Typography color="primary">{email}</Typography>
          </div>
          <div className={classes.infoField}>
            <Typography className={classes.infoLabel} variant="body1" color="textGray">
              Время работы
            </Typography>
            <Typography color="primary">с 9.00 до 18.00 (MSK)</Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
};
