import React, { FC } from 'react';
import { useDashboardPlugStyles } from './use-styles';

export const DashboardPlug: FC = () => {
  const classes = useDashboardPlugStyles();
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h2 className={classes.title}>Дашборды</h2>
        <p className={classes.description}>
          В этом разделе вы увидите дашборд для просмотра результатов по вашим оценочным сессиям.
          <br />
          Данные появятся после первого тестируемого, который пройдет ваш оценочную сессию.
        </p>
      </div>
      <div className={classes.imgWrapper}>
        <img src={`${process.env.PUBLIC_URL}/images/dashboard-preview.svg`} alt="" className={classes.img} />
      </div>
    </div>
  );
};
