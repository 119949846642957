import { createUseStyles } from 'react-jss';

export const MIN_SIDEBAR_WIDTH = 60;
export const DEFAULT_SIDEBAR_WIDTH = 280;
export const MIN_CONTENT_WRAPPER_WIDTH = 1200;

type RuleNames = 'main' | 'sidebar' | 'content' | 'resizeBlock' | 'resizeLine';

interface IUseStyleProps {
  sidebarWidth: number;
}

export const useStyles = createUseStyles<RuleNames, IUseStyleProps>(
  (theme) => ({
    main: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexGrow: 1,
      backgroundColor: theme.background.lightGray,
    },
    sidebar: {
      position: 'sticky',
      top: 64,
      maxHeight: 'calc(100vh - 64px)',
      overflow: 'auto',
      backgroundColor: theme.background.background,
      display: 'flex',
      maxWidth: (props): number => props.sidebarWidth,
      flexBasis: '100%',
    },
    resizeBlock: {
      position: 'sticky',
      top: 64,
      maxHeight: 'calc(100vh - 64px)',
      cursor: 'col-resize',
      userSelect: 'none',
      zIndex: 11,
      '&:hover > div, &:active > div': {
        paddingRight: 5,
      },
    },
    resizeLine: {
      position: 'absolute',
      backgroundColor: theme.palette.lines.main,
      height: '100%',
      paddingRight: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxWidth: '100%',
      minWidth: MIN_CONTENT_WRAPPER_WIDTH,
      flexGrow: 1,
    },
    [`@media (max-width: ${MIN_SIDEBAR_WIDTH + MIN_CONTENT_WRAPPER_WIDTH}px)`]: {
      resizeBlock: {
        cursor: 'auto',
        '&:hover > div, &:active > div': {
          paddingRight: 1,
        },
      },
      content: {
        minWidth: `calc(100vw - ${MIN_SIDEBAR_WIDTH}px)`,
      },
    },
    '@media (max-width: 1320px)': {
      sidebar: {
        display: 'none',
      },
    },
  }),
  { name: 'page' },
);
