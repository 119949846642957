import React, { FC, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { NotificationsContext } from './notifications.context';
import { NotificationItems } from './notifications.item';
import { INotification, INotificationsProps, NotificationsPositions } from './notifications.models';

const DEFAULT_TIMEOUT = 3000;

export const Notifications: FC<PropsWithChildren<INotificationsProps>> = ({
  position = 'top-right',
  children,
}) => {
  const [notifications, setNotification] = useState<INotification[]>([]);

  const push = (toast: Partial<INotification>): void => {
    setNotification((prev) => [...prev, {
      ...toast,
      id: Date.now(),
      title: toast.title ?? 'Успешно',
      type: toast.type ?? 'success',
      timeout: toast.timeout ?? DEFAULT_TIMEOUT,
      autoDelete: toast.autoDelete ?? true,
    }]);
  };

  const remove = (id: number): void => {
    setNotification((prev) => prev.filter((n) => n.id !== id));
  };

  const positionClasses: Record<NotificationsPositions, string> = {
    'top-right': 'top-8 right-3 transition animate-toast-in-right',
    'top-left': 'top-8 left-3 transition animate-toast-in-left',
    'bottom-left': 'bottom-3 left-3 transition animate-toast-in-left',
    'bottom-right': 'bottom-3 right-3 transition animate-toast-in-right',
  };

  const positionClassName = classNames(
    'fixed z-[99999] transition',
    positionClasses[position],
  );

  return (
    <NotificationsContext.Provider value={{
      push,
      remove,
    }}>
      {children}
      <div className={positionClassName}>
        {notifications.map((toast) => (
          <NotificationItems
            {...toast}
            key={toast.id}
          />
        ))}
      </div>
    </NotificationsContext.Provider>
  );
};
