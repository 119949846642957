import {
  IBuilderCoursesStore,
  IBuilderCourseStore,
  IBuilderStore,
} from '../../models/stores/builder.store';
import { IRootStore } from '../../models/stores/root.store';
import { BuilderCourseStore } from './builder-course';
import { BuilderCoursesStore } from './builder-course-list';

export class BuilderStore implements IBuilderStore {

  public readonly rootStore: IRootStore;

  public courses: IBuilderCoursesStore;
  public course: IBuilderCourseStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    this.courses = new BuilderCoursesStore(rootStore);
    this.course = new BuilderCourseStore(rootStore);
  }

}
