import { sdkGetCourseModel } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { ModelMappers } from '../mappers/model.mappers';
import { IModel, IModelStore } from '../models/stores/model.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class ModelStore implements IModelStore {

  public readonly rootStore: IRootStore;

  public data: IModel | null = null;
  public loading: boolean = false;
  public error: string | undefined = undefined;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset(): void {
    this.data = null;
    this.error = undefined;
    this.loading = false;
  }

  public async get(id: string): Promise<void> {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetCourseModel({
        baseURL: this.rootStore.config.builderAPI,
        data: { id },
        token: this.rootStore.auth.token,
      });

      runInAction(() => {
        this.data = ModelMappers.getModel(response);
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

}
