import { createUseStyles } from 'react-jss';

const bold = {
  fontWeight: 600,
};

const buttons = {
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  fontSize: 14,

  '& > button': {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    padding: '10px 24px',
    whiteSpace: 'nowrap',
  },
};

export const useProctoringStyles = createUseStyles(
  (theme) => ({
    wrapper: {
      padding: {
        top: 36,
        bottom: 36,
        right: 48,
        left: 48,
      },
    },
    scroll_box: {},
    table: {
      marginTop: 32,
      '& .Table-header': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(230px, 1fr))',
      },
      '& .Table-content': {
        '& .Table-content_row': {
          display: 'grid',
          gridTemplateColumns: 'repeat(4, minmax(230px, 1fr))',
        },
      },
    },
    download: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      border: 'none',
      color: theme.palette.primary.main,

      '&:hover': {
        background: 'none',
      },
    },
    registry: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    paperWrapper: {
      marginTop: 22,
      padding: {
        top: 20,
        bottom: 20,
        right: 40,
        left: 40,
      },
    },
    block__center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 100,
      margin: '0 auto',
    },
    search: {
      backgroundColor: theme.background.paper,
      marginTop: 24,
      padding: {
        top: 8,
        right: 16,
        bottom: 8,
        left: 16,
      },
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      borderRadius: 10,

      '& input': {
        width: '100%',
        outline: 'none',
      },

      '& svg': {
        width: 20,
        height: 20,
        color: theme.palette.secondaryText.main,
      },
    },
    between: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: '1rem',
    },
    inline: {
      display: 'inline',
    },
    updateAt: {
      margin: '10px',
    },
    updateAtLabel: {
      color: theme.palette.secondaryText.main,
    },
    updateAtDate: {
      color: theme.palette.gloomyBlue.main,
      fontSize: 16,
      fontWeight: 400,
    },
    actions: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
    },
    actions_with_search: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: 30,
    },
    multiSelect: {
      backgroundColor: '#FFFFFF',
    },
    bold: {
      borderColor: theme.palette.primary.main,
    },
    buttons: {
      ...buttons,
      borderColor: theme.palette.primary.main,
      color: '#fff',
    },
    upload: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderWidth: 1,
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      padding: '10px 24px',
      whiteSpace: 'nowrap',
    },
    '@media screen and (max-width: 1585px)': {
      between: {
        alignItems: 'flex-start',
      },
    },
    '@media screen and (max-width: 1270px)': {
      wrapper: {
        padding: '36px 30px',
      },
    },
    '@media screen and (max-width: 1060px)': {
      scroll_box: {
        background: 'linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: '100% 40px, 100% 40px, 100% 5px, 100% 5px',
        backgroundAttachment: 'local, local, scroll, scroll',
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
          width: '3px',
          height: '3px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: '#f5f5f7',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#909ebb',
          borderRadius: '10px',
        },
      },
    },
  }),
  { name: 'Proctoring' },
);

export const useProctoringIntroCardStyle = createUseStyles(
  (theme) => ({
    summary: {
      fontWeight: 500,
      fontFamily: 'Montserrat, sans-serif',
    },
    description: {
      marginBottom: 24,
    },
    list: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 12,

      '& > li > span': {
        marginRight: 16,
        color: theme.palette.primary.main,
      },
    },
    bold,
    buttons: {
      ...buttons,
      gap: 16,
      marginTop: 40,
    },
    upload: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderWidth: 1,
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      padding: '10px 24px',
      whiteSpace: 'nowrap',
    },
    download: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      border: 'none',
      color: theme.palette.primary.main,

      '&:hover': {
        background: 'none',
      },
    },
  }),
  { name: 'ProctoringIntroCard' },
);
