import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { CircularProgress } from '../../lab/circular-progress';
import { WarningIcon } from '../../lab/icons/warning';
import { IBlockProps } from './block.models';

export const Block = <TData extends object>(props: IBlockProps<TData>): ReactElement | null => {
  const stateBlockCN = 'w-full flex items-top justify-center py-16';

  if (props.loading) {
    return (
      <div className={stateBlockCN}>
        <CircularProgress width={24} height={24} />
      </div>
    );
  }

  if (props.error) {
    if (props.errorContent) {
      return props.errorContent(props.error, props.data);
    }

    return (
      <div className={stateBlockCN}>
        <div className="flex flex-col items-center gap-y-2">
          <div className="flex flex-col items-center gap-y-2 text-status-error">
            <WarningIcon width={64} height={64} />
            <div className="text-lg font-semibold">
              Неизвестная ошибка
            </div>
          </div>
          <Link className="text-sm text-primary-darkBlue" to={BrowserRoute.main} >
            Перейти на страницу курсов
          </Link>
        </div>
      </div>
    );
  }

  return props.children(props.data);
};
