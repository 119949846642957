import React, { FC, SVGProps } from 'react';

export const UploadIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.18683 3.50505L8.835 3.85699H8.83333H8.33333V3.85866V4.06581V12.667H7.66667V3.85699V2.64974L6.81308 3.50347L2.10747 8.20986L1.63625 7.73864L8.00002 1.37412L14.3637 7.73782L13.8924 8.20911L9.18683 3.50505ZM15 15.3337V16.0003H1V15.3337H15Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>

);
