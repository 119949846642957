import { ITableColumn } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { IRow } from './proctoring.models';

export const columns: ITableColumn<IRow>[] = [
  {
    name: 'ID пользователя',
    value: (row: IRow): string => row._id,
  },
  {
    name: 'ФИО пользователя',
    value: (row: IRow): string => row.fio,
  },
  {
    name: 'Телефон',
    value: (row: IRow): string => row.phone,
  },
  {
    name: 'Тип прокторинга',
    value: (row: IRow): string => row.type,
  },
];
