import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useAppStore } from '../../../stores/context.store';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      borderBottom: `2px solid ${theme.palette.lines.main}`,
    },
    nav: {
      display: 'flex',
      columnGap: 16,
      marginLeft: 48,
      color: theme.palette.secondaryText.main,
      fontSize: 16,
      lineHeight: '20px',
    },
    link: {
      display: 'inline-block',
      padding: '12px 8px',
      borderBottomWidth: 2,
      marginBottom: -2,
      whiteSpace: 'nowrap',
      '&:hover': {
        borderColor: '#73AAFF',
      },
    },
    activeLink: {
      color: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '@media (max-width: 768px)': {
      root: {
        overflow: 'auto',
        height: 'auto',
      },
      nav: {
        marginLeft: 0,
      },
      link: {
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 0,
      },
    },
  }),
  { name: 'navbar' },
);

export const AppTabs = (): JSX.Element => {
  const { config } = useAppStore();
  const classes = useStyles();

  return (
    <header className={cn(classes.root, 'scrollbar')}>
      <nav className={classes.nav}>
        <a className={classes.link} href={`${config.builderWEB}/courses?stage=published`}>
          Оценочные сессии
        </a>
        <a className={classes.link} href={`${config.builderWEB}/testers`}>
          Тестируемые
        </a>
        <Link to="/manager" className={cn(classes.activeLink, classes.link)}>
          Личный кабинет
        </Link>
        <a className={classes.link} href={`${config.builderWEB}/certificates`}>
          Сертификаты
        </a>
        <a className={classes.link} href={`${config.builderWEB}/report`}>
          Отчеты
        </a>
      </nav>
    </header>
  );
};
