import React, { FC, Fragment, ReactNode, useState } from 'react';
import cn from 'classnames';
import {
  Legend,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
} from 'recharts';
import { CustomTooltip } from './custom-tooltip';
import { IHandleHiddenOpts, IChartProps } from './result.models';
import { useChartStyles } from './use-styles';

export const GroupCourseChart: FC<IChartProps> = (props) => {
  const [hidden, setHidden] = useState<{ [key: string]: boolean }>({});
  const classes = useChartStyles();

  const getTitle = (key: number): string => {
    const competency = props.charts.find((chart) => chart.key === key);

    if (!competency) {
      return '—';
    }

    return `${key}. ${competency.title}`;
  };

  const handleHidden = (opts: IHandleHiddenOpts): void => {
    const { dataKey, inactive } = opts;

    setHidden((hide) => ({ ...hide, [dataKey as number]: !inactive }));
  };

  return (
    <div className={classes.wrapper}>
      <ResponsiveContainer height={400}>
        <AreaChart
          className={classes.groupChart}
          width={500}
          height={400}
          data={props.charts}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={[0, 1]} />
          {props.charts.map((el) => <XAxis dataKey={el.key} />)}
          <Tooltip
            content={(tooltip): ReactNode => (
              <CustomTooltip
                active={tooltip.active}
                title={getTitle(tooltip.label)}
                payload={tooltip.payload?.map((tool) => ({
                  color: tool.color,
                  name: tool.name,
                  value: tool.value,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  grade: tool.payload.grade[tool.dataKey as string],
                }))}
              />
            )}
          />

          {props.stages.map((el) => (
            <Area
              hide={hidden[el.dataKey]}
              dataKey={el.dataKey}
              name={el.name}
              stroke={el.color}
              fill={el.color}
              fillOpacity={0.3}
            />
          ))}
          <Legend iconType="plainline" onClick={handleHidden} />
        </AreaChart>
      </ResponsiveContainer>

      <div className={cn(classes.competencies, classes.marginX)}>
        {props.charts.map((chart) => (
          <Fragment key={chart.id}>
            <div className={classes.competencyKey}>{chart.key}</div>
            <div>{chart.title}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
