import { createUseStyles } from 'react-jss';

export const useCustomTooltipStyles = createUseStyles(
  (theme) => ({
    tooltip: {
      backgroundColor: theme.background.paper,
      padding: 16,
      borderWidth: 1,
      maxWidth: 400,
    },
    title: {
      fontWeight: 500,
      borderBottomWidth: 1,
      marginBottom: 8,
      paddingBottom: 8,
    },
  }),
  { name: 'CustomTooltip' },
);

export const useChartStyles = createUseStyles(
  (theme) => ({
    targetValueLine: {
      transform: 'translate(50%, 50%)',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      margin: '20px auto',
      wordBreak: 'break-word',
    },
    oneCourseWrapper: {
      maxWidth: 1000,
    },
    chart: {
      '& .recharts-default-legend': {
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        marginLeft: 'auto!important',

        '& .recharts-legend-item': {
          cursor: 'pointer',

          '&:hover': {
            filter: 'brightness(110%)',
          },
        },
      },
    },
    groupChart: {
      '& .recharts-default-legend': {
        display: 'flex',
        width: 'calc(100% - 32px)',
        flexWrap: 'wrap',
        margin: '0 32px !important',
        padding: '4px 0 12px !important',
        gap: '8px 16px',
        borderBottom: `1px solid ${theme.palette.lines.main}`,

        '& .recharts-legend-item': {
          cursor: 'pointer',
          margin: '0px 10px',

          '&:hover': {
            filter: 'brightness(110%)',
          },
        },
      },
    },
    competencies: {
      display: 'grid',
      gridTemplateColumns: 'minmax(11px, auto) 1fr',
      gap: 12,
      fontSize: 14,
      lineHeight: '16px',
    },
    marginX: {
      margin: '0 32px',
    },
    competencyKey: {
      color: theme.palette.secondaryText.main,
    },
  }),
  { name: 'RadarChart' },
);
