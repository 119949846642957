import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      padding: '36px 48px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      color: theme.palette.gloomyBlue.main,
    },
    label: {
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 28,
      fontWeight: 700,
      lineHeight: '36px',
    },
    courses: {
      fontFamily: theme.fontFamily.monserrat,
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      padding: 24,
      display: 'flex',
      columnGap: 40,
      borderRadius: '16px 16px 0 0',
    },
    coursesIcon: {
      padding: 16,
      borderRadius: 28,
      backgroundColor: '#FFFFFF14',
    },
    coursesInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    coursesLabel: {
      fontWeight: 500,
      color: theme.palette.activeDisabled.main,
    },
    coursesCount: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '40px',
    },
    course: {
      backgroundColor: theme.background.paper,
      padding: '24px 32px 32px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
      borderRadius: '0 0 16px 16px',
    },
    tabs: {
      columnGap: 16,
    },
    tab: {
      padding: '10px 16px',
    },
    '@media (max-width: 1024px)': {
      root: {
        padding: 24,
        gap: 0,
      },
      label: {
        marginBottom: 20,
      },
      courses: {
        columnGap: 24,
        padding: 24,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      course: {
        padding: 24,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    '@media (max-width: 768px)': {
      root: {
        padding: 0,
        paddingTop: 24,
      },
      label: {
        padding: '0 24px',
      },
    },
  }),
  { name: 'dashboard' },
);

export const useDashboardPlugStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      gap: 24,
      backgroundColor: theme.background.paper,
      margin: 24,
      borderRadius: 16,
      justifyContent: 'space-between',
      color: theme.palette.gloomyBlue.main,
    },
    info: {
      padding: {
        top: 80,
        bottom: 80,
        left: 80,
      },
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    title: {
      font: {
        size: 32,
        weight: 700,
        family: theme.fontFamily.monserrat,
      },
      lineHeight: '36px',
    },
    description: {
      fontSize: 18,
      lineHeight: '28px',
      color: theme.palette.secondaryText.main,
    },
    imgWrapper: {
      minWidth: '35%',
    },
    img: {
      marginLeft: 'auto',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'left',
    },
    '@media (max-width: 1024px)': {
      root: {
        flexDirection: 'column',
      },
      title: {
        fontSize: 28,
      },
      description: {
        fontSize: 16,
        lineHeight: '20px',
      },
      info: {
        padding: {
          top: 24,
          right: 24,
          left: 24,
        },
      },
    },
  }),
  { name: 'dashboard-plug' },
);
