import React from 'react';
import { createUseStyles } from 'react-jss';
import { Paper, PaperProps } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { UserIcon } from '../../lab/icons/user';
import { Typography } from '../../lab/typography';

type AccountProfileEmptyProps = PaperProps;

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: 40,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    userIcon: {
      stroke: theme.palette.lines.main,
      marginBottom: 16,
    },
    desc: {
      maxWidth: 500,
    },
  }),
  { name: 'AccountProfileEmpty' },
);

export const AccountProfileEmpty: React.FC<AccountProfileEmptyProps> = (props) => {
  const { className = '' } = props;
  const classes = useStyles();

  return (
    <Paper {...props} className={cn(className, classes.root)}>
      <div className={classes.wrapper}>
        <UserIcon className={classes.userIcon} />
        <Typography color="textGray" align="center" className={classes.desc}>
          К сожалению аккаунт-менеджер для вашей организации не найден. Обратитесь в тех. поддержку нашего сервиса для
          уточнения информации.
        </Typography>
      </div>
    </Paper>
  );
};
