import { createUseStyles } from 'react-jss';

export const useAccountProfileStyles = createUseStyles(
  (theme) => ({
    root: {
      padding: 40,
      display: 'flex',
    },
    avatar: {},
    box: {
      marginLeft: 80,
      flex: 1,
      paddingTop: 20,
    },
    fullName: {
      fontFamily: theme.fontFamily.monserrat,
    },
    infoBox: {
      display: 'flex',
      marginTop: 40,
      width: '100%',
      columnGap: 20,
    },
    infoField: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 12,
    },
    infoLabel: {},
    '@media (max-width: 1024px)': {
      root: {
        flexDirection: 'column',
        padding: 24,
      },
      avatar: {
        margin: '0 auto',
      },
      box: {
        margin: 0,
      },
      fullName: {
        textAlign: 'center',
      },
    },
    '@media (max-width: 768px)': {
      infoBox: {
        flexDirection: 'column',
        padding: 16,
        border: {
          width: 1,
          color: theme.palette.lines.main,
        },
        rowGap: 16,
      },
      infoField: {
        rowGap: 4,
      },
      infoLabel: {
        fontSize: 14,
      },
    },
  }),
);
