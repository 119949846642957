import {
  IBuilderOrganizationSimple,
  sdkAddOrganizationAccountFeedback,
  sdkGetOrganization,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { IUsersMessage } from '../models/entities/accountManager.models';
import { IAccountManagerStore } from '../models/stores/accountManager.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class AccountManagerStore implements IAccountManagerStore {

  public readonly rootStore: IRootStore;

  data: null | IBuilderOrganizationSimple<string> = null;
  loading: boolean = true;
  error: undefined | string = undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async sendMessage(message: Pick<IUsersMessage, 'appeal'>): Promise<void> {
    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
      });

      await sdkAddOrganizationAccountFeedback({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.auth.token,
        data: {
          text: message.appeal,
        },
      });

      toast({
        text: 'Ваше обращение отправлено!',
        loadingBar: true,
        type: 'success',
      });

      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  }

  public getAccountManager = async(): Promise<void> => {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
      });

      const organization = this.rootStore.user.data?.organization;

      if (!organization?.id) {
        throw new Error('organization id not found');
      }

      const response = await sdkGetOrganization({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.auth.token,
        data: {
          id: organization.id,
        },
      });

      runInAction(() => {
        this.data = response;
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  };

}
