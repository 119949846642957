import React, { ChangeEventHandler, Fragment } from 'react';
import { Button, CircularProgress, MultiSelect, Pagination, Table } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import classNames from 'classnames';
import { PROCTO_TYPES } from '../../../constants';
import { DownloadIcon } from '../../components/icons/download';
import { UploadIcon } from '../../components/icons/upload';
import { SearchIcon } from '../../lab/icons/search';
import { Typography } from '../../lab/typography';
import { IProctoringTableProps, IRow } from './proctoring.models';
import { useProctoringStyles } from './use-styles';
import { columns } from './utils';

export const ProctoringList: React.FC<IProctoringTableProps> = ({
  onDownloadRegistry,
  onDownloadAgreements,
  uploadRegistry,
  data,
  loading,
  currentPage,
  limit,
  searchValue = '',
  maxPaginationPage,
  multiSelectOptions,
  multiSelectIsLoading,
  multiSelectSearchChange,
  multiSelectInfinityScroll,
  multiSelectOnChange,
  changeLimit,
  searchChange,
  goToNextPage,
  goToPrevPage,
}) => {
  const classes = useProctoringStyles();

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    searchChange(event.target.value);
  };

  const list: IRow[] = data?.items
    .map((tester) => ({
      _id: tester.id_auth ?? '-',
      fio: `${tester.sur_name} ${tester.name} ${tester.patronymic}`.trim(),
      phone: tester.phone?.toString() ?? '-',
      type: PROCTO_TYPES[tester.is_procto] ?? '-',
    }));

  const TestersTable = Table<IRow>;

  return (
    <div className={classes.wrapper}>
      <Typography component="h2" color="primary" variant="h2" fontWeight="bolder">
        Прокторинг
      </Typography>
      <div className={classes.actions}>
        <div className={classes.actions_with_search}>
          <label className={classes.search}>
            <SearchIcon />
            <input type="text" placeholder="Введите запрос" value={searchValue} onChange={onSearchChange} />
          </label>
          <label className={classes.multiSelect}>
            <MultiSelect
              options={multiSelectOptions}
              handleSearch={multiSelectSearchChange}
              onChange={multiSelectOnChange}
              infinityScroll={multiSelectInfinityScroll}
              placeholder="Название оценочной сессии"
              isLoading={multiSelectIsLoading}
              clear
            />
          </label>
        </div>
        <Button size="none" variantStyle="standard" onClick={onDownloadAgreements}>
          <DownloadIcon className={classes.inline} />
          <span className={classes.bold}>Скачать шаблоны согласий</span>
        </Button>
      </div>
      <Paper className={classes.paperWrapper}>
        <Fragment>
          <div className={classes.between}>
            <div className={classes.registry}>
              <Chip label="Пользователи, согласившиеся на прокторинг" color="info" />
            </div>
            <div className={classes.buttons}>
              <Button size="sm" variant="gradient" onClick={onDownloadRegistry}>
                <DownloadIcon />
                <span>Сформировать реестр</span>
              </Button>
              <label
                htmlFor="tester-registry"
                className={classes.upload}
              >
                <UploadIcon />
                <span className={classes.bold}>Загрузить реестр</span>
              </label>
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                id="tester-registry"
                onChange={uploadRegistry}
              />
            </div>
          </div>
          {loading ? <CircularProgress className={classes.block__center} /> : (
            !list?.length ? (
              <div className={classes.block__center}>Не найдено.</div>
            ) : (
              <Fragment>
                <TestersTable className={classNames(classes.table, classes.scroll_box)} data={list} columns={columns} />
                <Pagination
                  limit={limit}
                  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                  limitsOptions={[10, 25, 50, 100]}
                  onLimitChange={changeLimit}
                  total={data.total}
                  minPage={1}
                  maxPage={maxPaginationPage}
                  page={currentPage}
                  onNextClick={goToNextPage}
                  onPrevClick={goToPrevPage}
                />
              </Fragment>
            ))}
        </Fragment>
      </Paper>
    </div>
  );
};
