import {
  sdkGetProctoTestersAgreements,
  sdkGetTestersRegistry,
  sdkUploadTestersRegistry,
} from '@unione-pro/unione.assmnt.sdk.webapp/lib/testers';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/notification';
import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { LOGGER_ACTION } from '../models/stores/logger.store';
import { IRegistryStore } from '../models/stores/registry.store';
import { IRootStore } from '../models/stores/root.store';
import { BuilderBackendRoutes } from '../routes/backend.routes';
import { getErrorMessage } from '../shared/error-message';
import { withoutEmptyProperties } from '../shared/without-empty-properties';

const ORGANIZATION_ID_IS_EMPTY_ERROR = 'Ваш пользователь удален из организации. Обратитесь в техническую поддержку.';

export class RegistryStore implements IRegistryStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;
  public error?: string = undefined;
  public proctoringAttentionModalOpen: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public get organizationId(): string | undefined {
    return this.rootStore.user.data?.organization?.id;
  }

  public reset(): void {
    this.error = undefined;
  }

  public proctoringAttentionModalClose(): void {
    this.proctoringAttentionModalOpen = false;
  }

  public downloadRegistry = async(courseId?: string): Promise<void> => {
    if (!this.organizationId) {
      toast({
        text: ORGANIZATION_ID_IS_EMPTY_ERROR,
        loadingBar: true,
        type: 'error',
      });

      return;
    }

    try {
      const response = await sdkGetTestersRegistry({
        baseURL: this.rootStore.config.systemAPI,
        token: this.rootStore.auth.token,
        responseType: 'blob',
        data: withoutEmptyProperties({
          organizationId: this.organizationId,
          courseId,
        }),
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_CREATE_REGISTRY,
        request: {
          'user-id': this.rootStore.user.data?.id,
          org_id: this.organizationId,
        },
      });

      const href = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', this.organizationId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(href);
    }
    catch (error) {
      if (error instanceof AxiosError) {
        const reader = new FileReader();
        reader.onload = (event): void => {
          if (event.target && event.target.result) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const error: { message: string } = JSON.parse(event.target.result as string);

            toast({
              text: error.message,
              loadingBar: true,
              type: 'error',
            });
          }
        };
        reader.readAsText(error.response.data);

        return;
      }

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.ERROR_CREATE_REGISTRY,
        request: {
          'user-id': this.rootStore.user.data?.id,
          org_id: this.organizationId,
        },
        error,
      });

      toast({
        text: getErrorMessage(error),
        loadingBar: true,
        type: 'error',
      });
    }
  };

  public downloadAgreements = async(courseId?: string): Promise<void> => {
    if (!this.organizationId) {
      toast({
        text: ORGANIZATION_ID_IS_EMPTY_ERROR,
        loadingBar: true,
        type: 'error',
      });

      return;
    }

    try {
      await sdkGetProctoTestersAgreements({
        baseURL: this.rootStore.config.systemAPI,
        token: this.rootStore.auth.token,
        data: withoutEmptyProperties({
          organizationId: this.organizationId,
          courseId,
        }),
      });

      this.rootStore.logger.sendLog({
        action: LOGGER_ACTION.CLICK_DOWNLOAD_AGREEMENTS,
        request: {
          'user-id': this.rootStore.user.data?.id,
          org_id: this.organizationId,
        },
      });

      const url = new URL(this.rootStore.config.systemAPI + BuilderBackendRoutes.testerAgreements);
      url.searchParams.set('organizationId', this.organizationId);
      if (courseId) {
        url.searchParams.set('courseId', courseId);
      }

      window.open(url);
    }
    catch (error) {
      if (error instanceof AxiosError) {
        const { message } = error.response.data;
        toast({
          text: message,
          loadingBar: true,
          type: 'error',
        });

        this.rootStore.logger.sendLog({
          action: LOGGER_ACTION.ERROR_DOWNLOAD_AGREEMENTS,
          request: {
            'user-id': this.rootStore.user.data?.id,
            org_id: this.organizationId,
          },
          error,
        });
      }
    }
  };

  public async uploadRegistry(files: FormData): Promise<void> {
    if (!this.organizationId) {
      toast({
        text: ORGANIZATION_ID_IS_EMPTY_ERROR,
        loadingBar: true,
        type: 'error',
      });

      return;
    }

    try {
      runInAction(() => {
        this.error = undefined;
        this.loading = true;
      });

      await sdkUploadTestersRegistry({
        baseURL: this.rootStore.config.systemAPI,
        token: this.rootStore.auth.token,
        data: files,
      });

      this.proctoringAttentionModalOpen = true;

      toast({
        text: 'файл успешно отправлен',
        loadingBar: true,
        type: 'success',
      });
    }
    catch (error) {
      toast({
        text: getErrorMessage(error),
        loadingBar: true,
        type: 'error',
      });

      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

}
