import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { TypographyProps } from './typography.models';

const PREFIX = 'Typography';
const useStyles = createUseStyles(
  () => ({
    root: { fontWeight: 400 },

    colorTextSecondary: {
      color: '#6F7C98',
    },
    colorTextGray: {
      color: '#909EBB',
    },
    colorPrimary: {
      color: '#3B4168',
    },
    variantH5: {
      fontSize: '18px',
      lineHeight: '24px',
    },
    variantH3: {
      fontSize: '24px',
      lineHeight: '28px',
    },
    variantH2: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    variantH1: {
      fontSize: '32px',
      lineHeight: '40px',
    },
    variantBody1: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    variantLabel: {
      color: '#909EBB',
      fontSize: '14px',
      lineHeight: '20px',
    },
    variantError: {
      color: 'red',
      fontSize: '14px',
      lineHeight: '20px',
    },
    fontWeightBolder: {
      fontWeight: 700,
    },
    fontWeightBold: {
      fontWeight: 600,
    },
    fontWeightMedium: {
      fontWeight: 500,
    },
    alignCenter: {
      textAlign: 'center',
    },
  }),
  { name: PREFIX },
);

export const Typography: React.FC<TypographyProps> = (props) => {
  const { className, component = 'div', variant = 'body1', color = 'textPrimary', fontWeight, align, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    {
      [classes.colorTextSecondary]: color === 'textSecondary',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorTextGray]: color === 'textGray',
      [classes.variantH2]: variant === 'h2',
      [classes.variantH5]: variant === 'h5',
      [classes.variantH1]: variant === 'h1',
      [classes.variantH3]: variant === 'h3',
      [classes.variantBody1]: variant === 'body1',
      [classes.variantLabel]: variant === 'label',
      [classes.variantError]: variant === 'error',
      [classes.fontWeightBold]: fontWeight === 'bold',
      [classes.fontWeightMedium]: fontWeight === 'medium',
      [classes.fontWeightBolder]: fontWeight === 'bolder',
      [classes.alignCenter]: align === 'center',
    },
    className,
  );

  return React.createElement(component, { className: rootCN, ...other });
};
