import { useState } from 'react';
import html2canvas from 'html2canvas';

interface IUseScreenshot {
  type: string,
  quality: number,
}

interface IUseScreenshotResponse {
  image?: string,
  error?: string,
  takeScreenShot(node: HTMLElement): Promise<string | void>,
}

interface ICreateFileNameProps {
  extension: string;
  names: string[];
}

export const useScreenshot = ({ type, quality }: IUseScreenshot): IUseScreenshotResponse => {
  const [image, setImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const takeScreenShot = (node: HTMLElement): Promise<string | void> => {
    if (!node) {
      return undefined;
    }

    return html2canvas(node)
      .then((canvas) => {
        const croppedCanvas = document.createElement('canvas');
        const croppedCanvasContext = croppedCanvas.getContext('2d');
        const cropPositionTop = 0;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        );

        const base64Image = croppedCanvas.toDataURL(type, quality);

        setImage(base64Image);
        return base64Image;
      })
      .catch(setError);
  };

  return {
    image,
    error,
    takeScreenShot,
  };
};

export const createFileName = ({
  extension,
  names,
}: ICreateFileNameProps): string => {
  if (!extension) {
    return '';
  }

  return `${names.join('')}.${extension}`;
};
