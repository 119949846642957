import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, RouteObject, Navigate } from 'react-router-dom';
import { BrowserRoute } from '../../routes/browser.routes';
import { Layout } from '../components/layout';
import { Page } from '../components/page';
import { Access } from '../content/access';
import { AccountManager } from '../content/account-manager';
import { Auth } from '../content/auth';
import { Dashboard } from '../content/dashboard';
import { Feedback } from '../content/feedback';
import { NotFound } from '../content/not-found';
import { Proctoring } from '../content/proctoring';
import { YandexMetrika } from '../content/yandex-metrika';

export const appRoutesDefinition: RouteObject[] = [
  {
    path: '/',
    element: (
      <YandexMetrika>
        <Auth>
          <Layout>
            <Access>
              <Outlet />
              <Toaster />
            </Access>
          </Layout>
        </Auth>
      </YandexMetrika>
    ),

    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: BrowserRoute.main,
        element: <Navigate to={BrowserRoute.accountManager} />,
      },
      {
        path: BrowserRoute.accountManager,
        element: (
          <Page>
            <AccountManager />
          </Page>
        ),
      },
      {
        path: BrowserRoute.dashboard,
        element: (
          <Page>
            <Dashboard />
          </Page>
        ),
      },
      {
        path: BrowserRoute.feedback,
        element: (
          <Page>
            <Feedback />
          </Page>
        ),
      },
      {
        path: BrowserRoute.proctoring,
        element: (
          <Page>
            <Proctoring />
          </Page>
        ),
      },
    ],
  },
];
