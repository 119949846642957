import React, { FC, PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import { Main } from '../../content/main';
import { AppTabs } from '../app-tabs/app-tabs';
import { Sidebar } from '../sidebar/sidebar.view';

const useStyles = createUseStyles((theme) => ({
  mobileSidebar: {
    display: 'none',
    backgroundColor: theme.background.paper,
  },
  '@media (max-width: 1320px)': {
    mobileSidebar: {
      display: 'flex',
    },
  },
  '@media (max-width: 768px)': {
    mobileSidebar: {
      margin: '20px 24px 0',
      borderRadius: 8,
      overflow: 'hidden',
    },
  },
}));

export const Page: FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();
  return (
    <Main sidebar={<Sidebar />}>
      <AppTabs />
      <div className={classes.mobileSidebar}>
        <Sidebar />
      </div>
      {children}
    </Main>
  );
};
