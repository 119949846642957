import { makeAutoObservable, runInAction } from 'mobx';
import { IRootStore } from '../models/stores/root.store';
import { IUserAuthStore } from '../models/stores/user.auth.store';
import { getErrorMessage } from '../shared/error-message';

export class UserAuthStore implements IUserAuthStore {

  public readonly rootStore: IRootStore;

  public token: string | null = null;
  public s3Token: string | null = null;
  public error: string | undefined = undefined;
  public loading: boolean = true;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public async getToken(): Promise<void> {
    try {
      runInAction(() => {
        this.token = null;
        this.loading = true;
        this.error = undefined;
      });

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.token = localToken;
          this.loading = false;
        });

        await this.getS3Token();

        return;
      }

      if (window.unione) {
        const unioneToken = await window.unione.getUnioneToken();
        if (typeof unioneToken !== 'string' || unioneToken === 'null' || unioneToken.length === 0) {
          runInAction(() => {
            this.token = null;
            this.loading = false;
          });

          return;
        }

        await this.getS3Token();

        runInAction(() => {
          this.token = unioneToken;
          this.loading = false;
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public getS3Token = async(): Promise<void> => {
    try {
      const localS3Token = this.rootStore.config.localS3Token;
      if (typeof localS3Token === 'string' && localS3Token.length > 0) {
        runInAction(() => {
          this.s3Token = localS3Token;
        });

        return;
      }

      if (window.unione) {
        const unioneS3Token = await window.unione.getS3Token();

        if (typeof unioneS3Token !== 'string' || unioneS3Token === 'null' || unioneS3Token.length === 0) {
          runInAction(() => {
            this.s3Token = null;
          });
          return;
        }

        runInAction(() => {
          this.s3Token = unioneS3Token;
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
      });
    }
  };

}
