import { IGetProctoTestersArgs, IGetProctoTestersRes, sdkGetProctoTesters } from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { IProctoTestersStore } from '../models/stores/procto-testers.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class ProctoTestersStore implements IProctoTestersStore {

  public readonly rootStore: IRootStore;

  public data: IGetProctoTestersRes | null;
  public loading: boolean = true;
  public fetching: boolean = true;
  public error: string | undefined = undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset(): void {
    this.data = null;
    this.loading = true;
    this.fetching = true;
    this.error = undefined;
  }

  public async get(data: IGetProctoTestersArgs): Promise<void> {
    try {
      runInAction(() => {
        this.data = null;
        this.fetching = true;
        this.error = undefined;
      });

      const response = await sdkGetProctoTesters({
        baseURL: this.rootStore.config.systemAPI,
        token: this.rootStore.auth.token,
        data,
      });

      runInAction(() => {
        this.data = response;
        this.loading = false;
        this.fetching = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
        this.fetching = false;
      });
      toast({
        text: getErrorMessage(error),
        loadingBar: true,
        type: 'error',
      });
    }
  }

}
