import React from 'react';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Paper, PaperProps } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { TextArea } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/text-area';
import cn from 'classnames';
import { useAppStore } from '../../../stores/context.store';
import { Typography } from '../../lab/typography';

const PREFIX = 'FeedbackForm';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: '32px 40px 40px',
      marginTop: 24,
    },
    description: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.secondaryText.main,
    },
    label: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.secondaryText.main,
      marginBottom: 4,
    },
    box: {
      display: 'flex',

      '& > div': {
        flex: 1,
      },
    },
    error: {
      borderColor: 'red',
    },
    field: {
      position: 'relative',
    },
    errorMessage: {
      position: 'absolute',
      bottom: '-15px',
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.lines.main}`,
    },
    submit: {
      marginTop: 24,
      backgroundImage: `linear-gradient(94.25deg, ${theme.gradient.blue.start} -0.43%, ${theme.gradient.blue.end} 54.69%)`,
    },
    '@media (max-width: 1024px)': {
      root: {
        padding: 24,
        marginTop: 20,
      },
    },
    '@media (max-width: 768px)': {
      submit: {
        width: '100%',
      },
    },
  }),
  { name: PREFIX },
);
interface IInputs {
  appeal: string;
}

type FeedbackFormProps = PaperProps;

export const FeedbackForm = (props: FeedbackFormProps): JSX.Element => {
  const { className, ...other } = props;

  const methods = useForm<IInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = methods;

  const { accountManager } = useAppStore();

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const onSubmit: SubmitHandler<IInputs> = (data): void => {
    accountManager.sendMessage(data);
    reset();
  };

  return (
    <Paper className={rootCN} {...other}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.box}>
            <div className={classes.field}>
              <div className={classes.label}>Ваше обращение</div>
              <Controller
                control={control}
                name="appeal"
                rules={{ required: 'Обязательно для заполнения!' }}
                render={({ field }): JSX.Element => (
                  <TextArea
                    placeholder="Введите текст вашего обращения"
                    rows={5}
                    autoSize
                    value={field.value}
                    onChange={(e): void => field.onChange(e)}
                    className={errors.appeal ? classes.error : ''}
                  />
                )}
              />
              <Typography className={classes.errorMessage} variant="error">
                {errors?.appeal?.message || ''}
              </Typography>
            </div>
          </div>

          <Button size="sm" className={classes.submit} type="submit">
            Отправить обращение
          </Button>
        </form>
      </FormProvider>
    </Paper>
  );
};
