import React, { ReactElement, useContext, FC, useState, createRef } from 'react';
import { DisplayResults } from '@unione-pro/unione.assmnt.sdk.webapp';
import {
  Button,
  Chip,
  CloseIcon,
  IMultiSelectOption,
  IOption,
  Modal,
  MultiSelect,
  Select,
  toast,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { createFileName, useScreenshot } from '../../../../../../shared/takeScreenshot';
import { appStoreContext } from '../../../../../../stores/context.store';
import { Block } from '../../../../../components/block';
import { GroupCourseChart } from '../../../../../components/chart';
import { ICourseTabProps } from '../CourseTab.model';
import { useStyles } from './use-styles';

const IS_IT_OPTIONS = [{ label: ' - ' }, { label: 'Да', value: 1 }, { label: 'Нет', value: 0 }];

export const GroupCourse: FC<ICourseTabProps> = observer(
  ({ moreCourses, searchCourses, changeSettings, show }) => {
    const { courseDashboardStore, dashboardStore, builder, testing } = useContext(appStoreContext);
    const [isIt, setIsIt] = useState(undefined);
    const [industryId, setIndustryId] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [chosenCourses, setChosenCourses] = useState([]);
    const [courseOnDashboard, setCourseOnDasboard] = useState([]);

    const industryIdChangeHandler = (newList: IMultiSelectOption[]): void => {
      setIndustryId(newList.map((industry) => industry.value));
    };

    const chosenCoursesChangeHandler = (newList: IMultiSelectOption[]): void => {
      setChosenCourses(newList);
    };

    const classes = useStyles();

    const result = testing.courseTestingResults.data;

    const changeIsItHandler = ({ value }: IOption): void => {
      if (typeof value === 'undefined') {
        setIsIt(value);
        return;
      }
      setIsIt(Boolean(value));
    };

    const openModal = (): void => {
      changeSettings({ industry_id: industryId.join(','), is_it: isIt });
      setIsOpen(true);
    };

    const closeModal = (): void => {
      setIsOpen(false);
      setChosenCourses([]);
    };

    const createDashboard = (): void => {
      const courses = chosenCourses.map(({ label }) => label);
      setCourseOnDasboard(courses);
      const courseIds = chosenCourses.map(({ value }) => value).join(',');
      courseDashboardStore.get(courseIds);
      closeModal();
    };

    const imageContainerRef = createRef<HTMLDivElement>();

    const { takeScreenShot } = useScreenshot({
      type: 'image/png',
      quality: 1.0,
    });

    const downloadImage = async(): Promise<void> => {
      const screenshot = await takeScreenShot(imageContainerRef.current);

      if (typeof screenshot !== 'string') {
        toast({
          text: 'Произошла ошибка, повторите снова.',
          loadingBar: true,
          type: 'error',
        });
      }

      const a = document.createElement('a');
      a.href = screenshot as string;
      a.download = createFileName({ extension: 'png', names: ['dashboard results'] });
      a.click();
    };

    return (
      <div className={classes.root}>
        {show && (
          <div className={classes.courseHeader}>
            <div className={classes.courseSetting}>
              <div className={classes.courseSettingWithLabel}>
                <p className={classes.courseSettingLabel}>Отраслевые принадлежности</p>
                <MultiSelect
                  placeholder="Выберите отраслевые принадлежности"
                  defaultValue={industryId}
                  options={dashboardStore.dictionaryOptions}
                  className={classes.courseSelect}
                  onChange={industryIdChangeHandler}
                  showChips
                  removeChip
                  clear
                />
              </div>
              <div className={classes.courseSettingWithLabel}>
                <p className={classes.courseSettingLabel}>Принадлежит к программам ИТ</p>
                <div className={classes.inputButtonWrapper}>
                  <Select
                    className={classes.courseSelect}
                    defaultValue={isIt}
                    options={IS_IT_OPTIONS}
                    onChange={changeIsItHandler}
                  />
                </div>
              </div>
              <div className={classes.courseActions}>
                <Button variant="primary" className={classes.courseButton} size="none" onClick={openModal}>
                  Выбрать оценочные сессии
                </Button>
                {dashboardStore.data && !courseDashboardStore.loading && (
                  <Button
                    variant="outlined"
                    onClick={downloadImage}
                    className={cn(classes.courseButton, classes.hideMobile)}
                    size="none"
                  >
                    Скачать в PNG
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {dashboardStore.data && (
          <div className={classes.chosenCourse}>
            Оценочные сессии:
            {courseOnDashboard.map((courseLabel) => (
              <Chip label={courseLabel} />
            ))}
          </div>
        )}

        <Block
          data={dashboardStore.data}
          loading={courseDashboardStore.loading}
          children={(dashboard): ReactElement => (
            <div style={{ display: dashboard ? 'block' : 'none' }} ref={imageContainerRef}>
              {dashboard && (
                <div className={classes.courseInfo}>
                  {dashboard.map((stage) => (
                    <div key={stage.order} className={classes.courseInfoItem}>
                      <p className={classes.courseInfoLabel}>{stage.name}</p>
                      <p className={classes.courseInfoCount}>{stage.count}</p>
                    </div>
                  ))}
                </div>
              )}
              {result && (
                <GroupCourseChart
                  charts={result.charts}
                  stages={result.stages}
                  displayResults={DisplayResults.competency}
                />
              )}
            </div>
          )}
        />
        {isOpen && (
          <Modal size="md" color="blue" open>
            <div className={classes.modalHeader}>
              <h3>Выберите оценочные сессии</h3>
              <CloseIcon onClick={closeModal} width={24} height={24} color="#909EBB" cursor="pointer" />
            </div>
            <div className={classes.courseSettingWithLabel}>
              <p className={classes.courseSettingLabel}>Оценочные сессии</p>
              <MultiSelect
                value={chosenCourses}
                placeholder="Выберите оценочные сессии"
                options={builder.courses.options as IMultiSelectOption[]}
                className={classes.courseSelect}
                onChange={chosenCoursesChangeHandler}
                infinityScroll={moreCourses}
                isLoading={builder.courses.fetching}
                handleSearch={searchCourses}
                showChips
                removeChip
                clear
              />
            </div>
            <div className={classes.modalFooter}>
              <Button
                variant="primary"
                className={classes.courseButton}
                size="none"
                onClick={createDashboard}
                disabled={!chosenCourses.length}
              >
                Сформировать
              </Button>
              <Button variant="outlined" onClick={closeModal} className={classes.courseButton} size="none">
                Отмена
              </Button>
            </div>
          </Modal>
        )}
      </div>
    );
  },
);
