import React, { FC, ReactNode, useEffect } from 'react';

interface IWithFooter {
  children?: ReactNode;
}

const enableFooter = (): void => {
  const footer = document.getElementById('footer');
  if (footer) {
    footer.style.display = 'block';
  }
};

export const WithFooter: FC<IWithFooter> = ({ children }) => {
  useEffect(() => enableFooter(), []);

  return <>{children}</>;
};
