import React, { FC, MouseEventHandler, PropsWithChildren, useState } from 'react';
import { IMainProps } from './main.models';
import { DEFAULT_SIDEBAR_WIDTH, MIN_CONTENT_WRAPPER_WIDTH, MIN_SIDEBAR_WIDTH, useStyles } from './use-styles';

export const Main: FC<PropsWithChildren<IMainProps>> = (props) => {
  const [sidebarWidth, setSidebarWidth] = useState<number>(DEFAULT_SIDEBAR_WIDTH);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const classes = useStyles({ sidebarWidth });
  const MAX_SIDEBAR_WIDTH = document.documentElement.clientWidth - MIN_CONTENT_WRAPPER_WIDTH;

  const startResizing = (): void => {
    setIsResizing(true);
  };

  const finishResizing = (): void => {
    setIsResizing(false);
  };

  const handleResize: MouseEventHandler = (event) => {
    if (!isResizing) {
      return;
    }

    const newWidth = event.clientX;

    if (newWidth < MIN_SIDEBAR_WIDTH) {
      setSidebarWidth(MIN_SIDEBAR_WIDTH);
      return;
    }

    if (newWidth > MAX_SIDEBAR_WIDTH) {
      setSidebarWidth(MAX_SIDEBAR_WIDTH);
      return;
    }

    setSidebarWidth(newWidth);
  };

  return (
    <main className={classes.main} onMouseMove={handleResize} onMouseUp={finishResizing}>
      <aside className={classes.sidebar}>
        {props.sidebar}
      </aside>
      <div className={classes.resizeBlock} onMouseDown={startResizing}>
        <div className={classes.resizeLine}></div>
      </div>
      <div className={classes.content}>{props.children}</div>
    </main>
  );
};
